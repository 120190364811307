'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { getUrlParams } from '../../utils/url';
import { Utils } from '../../utils/utils';
import {
  calculateHoursPerDay,
  renderBalanceErniGraph,
} from '../balance/balance.component.js';
import { getColour } from '../../shared/components/chart/chart-helpers.js';

let renderMercadosPrecios = (() => {
  let init = () => {};

  const renderVolumeGraph = async (data) => {
    const indicators = data.indicators;
    let graphColors = [
      '#006699',
      '#85B200',
      '#FFCF09',
      '#00A2D8',
      '#D90000',
      '#717171',
      '#FD8FFF',
      '#FF7F00',
    ];
    const indcatorLength = indicators.length;
    let sumaTotal = 0;
    const arrGeneMeFilter = indicators
      .map((elemnt, index) => {
        return {
          id: elemnt.id,
          alias: elemnt.alias,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          color: graphColors[index],
        };
      })
      .filter((el, index) => {
        if (indcatorLength !== index + 1) {
          return el;
        }
      });

    let sumPercent = 0;
    let finalIn = arrGeneMeFilter.at(-1).id;

    arrGeneMeFilter.map((te) => {
      sumaTotal += te.value;
      if (te.id !== finalIn) {
        sumPercent += te.value;
      }
    });

    let options = {
      target: d3.select('#mypVolumenWidgetView .chari-chart').node(),
      innerArrow: true,
      innerRadius: 0.7,
      hoverFade: 1,
    };

    let pie = new Charicharts.Pie(options, arrGeneMeFilter);
    let totalVolume = document.querySelector('#total-volume');
    totalVolume.innerHTML = '';
    totalVolume.innerHTML = `${Utils.toNumber(
      indicators[8]?.values[0]?.y,
      1,
      'comma'
    )}<span class="unit">MWh</span>`;

    let ulLegend = d3
      .select('#mypVolumenWidgetView .volume-leyends')
      .append('ul')
      .attr('class', 'esios-listEnergyItems__list')
      .style('margin-top', '30px');

    let keys = ulLegend
      .selectAll('.key')
      .data(arrGeneMeFilter)
      .enter()
      .append('li')
      .attr('class', 'esios-listEnergyItems__item-with-value');

    keys
      .html(
        (item, index) =>
          `<div class="esios-listEnergyItems__label ">
          <span
            class="esios-listEnergyItems__decorator"
            style="background: ${graphColors[index]};"
          ></span>
            ${item.alias}
        </div>`
      )
      .on('mouseover', function (d, i) {
        if (pie.innerArrow) {
          pie.innerArrow.moveTo(d.id);
        }
      });

    const graphPieLegendCenter = document.querySelector(
      '#mypVolumenWidgetView .chari-chart'
    );

    graphPieLegendCenter.insertAdjacentHTML(
      'beforeend',
      `  <div class="chartLegend"></div>`
    );
    pie.on('Pie-piece/mouseover', function (arrGeneMeFilter, d) {
      const centerLegendContent = document.querySelector(
        '#mypVolumenWidgetView .chartLegend'
      );
      let valueToShow = '-';
      if (arrGeneMeFilter.value == 0) {
        valueToShow = '-';
      } else {
        valueToShow = Utils.toNumber(
          (arrGeneMeFilter.value / sumPercent) * 100,
          1,
          'comma'
        );
      }

      centerLegendContent.innerHTML = `
    <h3 class="title">${arrGeneMeFilter.data.alias}</h3>
    <div class="value">
    ${valueToShow}
      <div class="unit">%</div>
    </div>
    `;
    });

    if (pie.pie) {
      pie.pie.triggerMouseover(1);
    }

    var aspect = 280 / 280,
      chart = d3.select('#mypVolumenWidgetView .chari-chart');

    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    const dataLength = indicators[0].values.length;
    const rectNoData1 = document.getElementById('mypNoDataRect1');
    const widget1 = document.getElementById('mypGraphic1');
    if (dataLength != 0) {
      widget1.classList.remove('no-data-graphic');
      rectNoData1.classList.add('hidden');
      document
        .querySelector('#mypGraphic1 .chartLegend')
        .classList.remove('hidden');
    } else {
      widget1.classList.add('no-data-graphic');
      widget1.classList.add('thin');
      rectNoData1.classList.remove('hidden');
      document.querySelector('#mypGraphic1 > svg').classList.add('hidden');
      document
        .querySelector('#mypGraphic1 .chartLegend')
        .classList.add('hidden');
    }
  };

  const renderMoreDataWidget = async (data) => {
    let htmlData = `<li class="esios-also__item col-xs-6 col-12">
    <span class="esios-also__icon-link"></span>
    <a href="/${i18next.t('routes.lng')}/${i18next.t(
      'routes.supply_curves'
    )}/">${i18next.t('supply_curves')}</a>
  </li>`;
    data.map((el) => {
      htmlData += `
    <li class="esios-also__item col-xs-6 col-12 is-xs-hidden">
      <span class="esios-also__icon-analysis"></span>
      <a href="/${i18next.t('routes.lng')}/${i18next.t('routes.analysis')}/${
        el.id
      }"
        >${el.name}</a
      >
    </li>`;
    });

    document.getElementById('more-myp').innerHTML = htmlData;
  };

  const renderUnitaryCostGraph = async (data) => {
    let graphColors = [
      '#006699',
      '#85B200',
      '#FFCF09',
      '#00A2D8',
      '#D90000',
      '#717171',
      '#FD8FFF',
      '#FF7F00',
    ];

    const indicators = data.indicators;

    const renovableFilter = [805, 808, 10377, 10380, 814, 1277, 10403];

    const extraInfoInModal = [792, 795, 10379, 10382, 801, 1276, 10404];
    const extraInfoInModalTwo = [779, 782, 10378, 10381, 788, 1275, 10405];

    const tableFilter = [817, 804, 791, 856, 843, 830, 10028, 10029];

    const arrGeneMedFilter = indicators.filter((elemnt) => {
      if (renovableFilter.includes(elemnt.id)) {
        return elemnt;
      }
    });
    const arrGeneMedExtraInfoFilter = indicators.filter((elemnt) => {
      if (extraInfoInModal.includes(elemnt.id)) {
        return elemnt;
      }
    });
    const arrGeneMedExtraInfoFilterTwo = indicators.filter((elemnt) => {
      if (extraInfoInModalTwo.includes(elemnt.id)) {
        return elemnt;
      }
    });

    const arrUnitaryTable = indicators
      .filter((elemnt) => {
        if (tableFilter.includes(elemnt.id)) {
          return elemnt;
        }
      })
      .map((el) => {
        return {
          value: el.values.length > 0 ? el.values[0].y : 0,
        };
      });

    const arrExtraInfo = arrGeneMedExtraInfoFilter.map((elemnt, index) => {
      return {
        id: elemnt.id,
        pos: index,
        [renovableFilter[index]]:
          elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: graphColors[index],
      };
    });
    const arrExtraInfoTwo = arrGeneMedExtraInfoFilterTwo.map(
      (elemnt, index) => {
        return {
          id: elemnt.id,
          pos: index,
          [renovableFilter[index]]:
            elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          name: elemnt.short_name,
          value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
          color: graphColors[index],
        };
      }
    );
    const finalExtraInfo = [];
    const finalExtraInfoTwo = [];

    const arrGeneMed = [];
    arrExtraInfo.forEach(function (a) {
      finalExtraInfo[extraInfoInModal.indexOf(a.id)] = a;
    });
    arrExtraInfoTwo.forEach(function (a) {
      finalExtraInfoTwo[extraInfoInModalTwo.indexOf(a.id)] = a;
    });
    const arrGenMedPrev = arrGeneMedFilter.map((elemnt, index) => {
      return {
        id: elemnt.id,
        pos: index,
        name: elemnt.short_name,
        value: elemnt.values.length > 0 ? elemnt.values[0].y : 0,
        color: graphColors[index],
      };
    });

    arrGenMedPrev.forEach(function (a) {
      arrGeneMed[renovableFilter.indexOf(a.id)] = a;
    });

    let options = {
      target: d3.select('#mypCosteWidgetView .chari-chart').node(),
      orientation: 'vertical',
      margin: '10 25 0 220',
      gridTicks: 7,
    };
    let graphTable = document.querySelector(
      '#mypCosteWidgetView .table-summary'
    );
    graphTable.innerHTML = `
    <table id="table-summary-laptop">
    <thead>
      <tr>
        <th></th>
        <th>${i18next.t('total')}</th>
        <th>${i18next.t('free_trading_company')}</th>
        <th>${i18next.t('reference_trading_company')}</th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 0;">
        <th>${i18next.t('average_price')} <span class="unit">€/MWh</span></th>
        <td class="is-big-number is-bold">${Utils.toNumber(
          arrUnitaryTable[2].value,
          2,
          'comma'
        )}</td>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[1].value,
          2,
          'comma'
        )}</td>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[0].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('energy')} <span class="unit">MWh</span></th>
        <td class="is-small-number is-bold">${Utils.toNumber(
          arrUnitaryTable[5].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[4].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[3].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('market_share')} <span class="unit">%</span></th>
        <td></td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[6].value,
          1,
          'comma'
        )}</td>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[7].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-ap">
    <tbody>
      <tr>
        <th class="th-title">${i18next.t(
          'average_price'
        )} <span class="unit th-title">€/MWh</span></th>
      </tr>
      <tr>
        <th>${i18next.t('total')}</th>
        <td class="is-big-number is-bold">${Utils.toNumber(
          arrUnitaryTable[2].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('free_trading_company')}</th>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[1].value,
          2,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('reference_trading_company')}</th>
        <td class="is-big-number is-medium-bold">${Utils.toNumber(
          arrUnitaryTable[0].value,
          2,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-energy">
    <tbody>
      <tr>
        <th class="th-title">${i18next.t(
          'energy'
        )} <span class="unit th-title">MWh</span></th>
      </tr>
      <tr>
        <th>${i18next.t('total')}</th>
        <td class="is-small-number is-bold">${Utils.toNumber(
          arrUnitaryTable[5].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('free_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[4].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('reference_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[3].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>

  <table id="table-summary-mobile-market">
    <tbody>
      <tr>
        <th class="th-title">${i18next.t(
          'market_share'
        )} <span class="unit th-title">%</span></th>
      </tr>
      <tr>
        <th>${i18next.t('free_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[6].value,
          1,
          'comma'
        )}</td>
      </tr>
      <tr>
        <th>${i18next.t('reference_trading_company')}</th>
        <td class="is-small-number">${Utils.toNumber(
          arrUnitaryTable[7].value,
          1,
          'comma'
        )}</td>
      </tr>
    </tbody>
  </table>
    `;

    let barPercentage = new Charicharts.PercentageBar(options, arrGeneMed);

    const graphPie = document.querySelector(
      '#mypCosteWidgetView  .chari-chart'
    );
    graphPie.insertAdjacentHTML(
      'beforeend',
      `
      <div id="infoWin" class="informativeWindow">
        <div class="widget-stacked-bar-tooltip">
        </div>
        <div id="arrowForInfo" class="widget-stacked-bar-tooltip-arrow"></div>
      </div>
    `
    );

    const graphPieLegendCenter = document.querySelector(
      '#mypCosteWidgetView .widget-stacked-bar-tooltip'
    );
    graphPieLegendCenter.innerHTML = `
        <div class="tooltip-container">
          <div class="tooltip-header" style="border-color: ${
            arrGeneMed[0].color
          };">
            <h3>${arrGeneMed[0].name}</h3>
            <div class="value is-medium-num">
              ${
                arrGeneMed[0].value === 0
                  ? '-'
                  : Utils.toNumber(arrGeneMed[0].value, 2, 'comma')
              }<span class="unit">€/MWh</span>
            </div>
          </div>
          <div class="tooltip-data">
            <div class="tooltip-row">
              <div class="label">${i18next.t('free_trading_company')}</div>
              <div class="value is-small-num">
                ${
                  finalExtraInfo[0].value === 0
                    ? '-'
                    : Utils.toNumber(finalExtraInfo[0].value, 2, 'comma')
                }<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-row">
              <div class="label">${i18next.t('reference_trading_company')}</div>
              <div class="value is-small-num">
                ${
                  finalExtraInfoTwo[0].value === 0
                    ? '-'
                    : Utils.toNumber(finalExtraInfoTwo[0].value, 2, 'comma')
                }<span class="unit">€/MWh</span>
              </div>
            </div>
          </div>
        </div>
      `;

    barPercentage.on('Bar-piece/mouseover', function (arrGeneMed, d) {
      document.getElementById('arrowForInfo').style.top = `${
        d[1] <= 324 ? d[1] : 324
      }px`;
      document.getElementById('arrowForInfo').style.display =
        d[1].value === 0 ? 'none' : 'flex';
      graphPieLegendCenter.style.top = `${d[1] <= 70 ? d[1] : 70}px`;
      graphPieLegendCenter.innerHTML = `
        <div class="tooltip-container">
          <div class="tooltip-header" style="border-color: ${
            arrGeneMed.color
          };">
            <h3>${arrGeneMed.name}</h3>
            <div class="value is-medium-num">
              ${
                arrGeneMed.value === 0
                  ? '-'
                  : Utils.toNumber(arrGeneMed.value, 2, 'comma')
              }<span class="unit">€/MWh</span>
            </div>
          </div>
          <div class="tooltip-data">
            <div class="tooltip-row">
              <div class="label">${i18next.t('free_trading_company')}</div>
              <div class="value is-small-num">
          
              ${
                finalExtraInfo[arrGeneMed.pos][arrGeneMed.id] === 0
                  ? '-'
                  : Utils.toNumber(
                      finalExtraInfo[arrGeneMed.pos][arrGeneMed.id],
                      2,
                      'comma'
                    )
              }<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-row">
              <div class="label">${i18next.t('reference_trading_company')}</div>
              <div class="value is-small-num">
                ${
                  finalExtraInfoTwo[arrGeneMed.pos][arrGeneMed.id] === 0
                    ? '-'
                    : Utils.toNumber(
                        finalExtraInfoTwo[arrGeneMed.pos][arrGeneMed.id],
                        2,
                        'comma'
                      )
                }<span class="unit">€/MWh</span>
              </div>
            </div>
          </div>
        </div>
      `;
    });

    /**CREACIÓN DE LEYENDA */
    let ulLegend = d3
      .select('#mypCosteWidgetView .volume-leyends')
      .append('ul')
      .attr('class', 'esios-listEnergyItems__list')
      .style('margin-top', '30px');

    let keys = ulLegend
      .selectAll('.key')
      .data(arrGeneMed)
      .enter()
      .append('li')
      .attr('class', 'esios-listEnergyItems__item-with-value');

    keys
      .html(
        (item, index) => `<div class="esios-listEnergyItems__label ">
            <span class="esios-listEnergyItems__decorator"
              style="background: ${graphColors[index]};"></span>${item.name}
          </div>`
      )
      .on('mouseover', function (d, i) {
        const graphPieLegendCenter = document.querySelector(
          '#mypCosteWidgetView .widget-stacked-bar-tooltip'
        );
        document.getElementById('arrowForInfo').style.top = `${
          d.y0 + d.y1 / 2
        }%`;
        document.getElementById('arrowForInfo').style.display =
          d.value === 0 ? 'none' : 'flex';

        graphPieLegendCenter.style.top = `${d.y0 <= 0 ? 0 : d.y0 - 16}px`;

        graphPieLegendCenter.innerHTML = `
          <div class="tooltip-container ${324 - d.y1 <= 72 ? 324 - d.y1 : 72}">
          
            <div class="tooltip-header" style="border-color: ${d.color};">
              <h3>${d.name}</h3>
              <div class="value is-medium-num">
                ${
                  d.value === 0 ? '-' : Utils.toNumber(d.value, 2, 'comma')
                }<span class="unit">€/MWh</span>
              </div>
            </div>
            <div class="tooltip-data">
              <div class="tooltip-row">
                <div class="label">${i18next.t('free_trading_company')}</div>
                <div class="value is-small-num">
                  ${
                    finalExtraInfo[d.pos][d.id] === 0
                      ? '-'
                      : Utils.toNumber(finalExtraInfo[d.pos][d.id], 2, 'comma')
                  }<span class="unit">€/MWh</span>
                </div>
              </div>
              <div class="tooltip-row">
                <div class="label">${i18next.t(
                  'reference_trading_company'
                )}</div>
                <div class="value is-small-num">
                  ${
                    finalExtraInfoTwo[d.pos][d.id] === 0
                      ? '-'
                      : Utils.toNumber(
                          finalExtraInfoTwo[d.pos][d.id],
                          2,
                          'comma'
                        )
                  }<span class="unit">€/MWh</span>
                </div>
              </div>
            </div>
          </div>
        `;
      });

    var aspect = 400 / 300,
      chart = d3.select('#mypCosteWidgetView .chari-chart');

    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });

    const dataLength = indicators[0].values.length;
    const window = document.getElementById('infoWin');
    const rectNoData2 = document.getElementById('mypNoDataRect2');
    const widget2 = document.getElementById('mypGraphic2');
    if (dataLength != 0) {
      widget2.classList.remove('no-data-graphic');
      rectNoData2.classList.add('hidden');
      window.classList.remove('hidden');
    } else {
      window.classList.add('hidden');
      widget2.classList.add('no-data-graphic');
      widget2.classList.add('thin');
      rectNoData2.classList.remove('hidden');
      document.querySelector('#mypGraphic2 svg').classList.add('hidden');
      document.querySelector('#mypGraphic2 infoWin')?.classList.add('hidden');
      // Modificar el estilo de la caja sin datos para centrar el contenido
      let noDataContainer = document.querySelector('#mypNoDataRect2');
      noDataContainer.style.display = 'flex'; // Asegurar que sea flex
      noDataContainer.style.justifyContent = 'center'; // Centrar horizontalmente
      noDataContainer.style.alignItems = 'center'; // Centrar verticalmente
    }
  };

  const renderDesviosWidget = async (data) => {
    let { date } = getUrlParams();

    const indicators = data.indicators;
    const graphColors = ['#02669C', '#666666', '#DDDDDD'];
    let length = 24;
    let parsedData = indicators.map((el, index) => {
      if (el.time === 218) {
        length = el.values.length;
      }
      if (index === 0) {
        return {
          id: el.id,
          alias: el.alias,
          geo_id: el.geo_id,
          short_name: el.short_name,
          type: 'line',
          // ! Se le debe de pasar la fecha
          values: processValues(length, el.values, date),
          // values: el.values,
          magnitud: el.magnitud,
          dots: true,
          interpolation: 'linear',
          color: graphColors[index],
        };
      } else if (index === 1) {
        return {
          id: el.id,
          alias: el.alias,
          geo_id: el.geo_id,
          short_name: el.short_name,
          type: 'line',
          values: processValues(length, el.values, date, true),
          magnitud: el.magnitud,
          dots: true,
          interpolation: 'linear',
          color: graphColors[index],
        };
      }
    });
    if (parsedData.length == 3) {
      parsedData.pop();
    }
    // Verificar si el primer objeto tiene la variable 'tiempo' y si el id es 218
    let trailConfig;
    if (indicators[0]?.tiempo?.[0]?.id === 218) {
      trailConfig = {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por intervalos de 15 minutos
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          let { date } = getUrlParams(); // Suponiendo que estás obteniendo la fecha desde los parámetros de la URL
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');

          // Si la fecha actual es diferente a la fecha del parámetro, empieza desde el comienzo del día
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours(0)
              .minutes(0)
              .format();
          } else {
            // Si la fecha es la misma que la actual, devuelve la fecha y hora actual
            return moment().tz('Europe/Madrid').format();
          }
        },
      };
    } else {
      // Configuración por defecto
      trailConfig = {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          let { date } = getUrlParams();
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours('00')
              .minutes('00')
              .format();
          } else {
            return moment().tz('Europe/Madrid').format();
          }
        },
      };
    }
    var options = {
      target: d3.select('#mypPrecioWidgetView .chari-chart').node(),
      margin: '40 40 25 45',
      dots: true,
      trail: trailConfig,
      xaxis: {
        ticks: [d3.time.hours, 1],
        textMarginLeft: 50,
        bottom: {
          // Otra opción
          // domain: [1640991600000, 1641074400000],
          label: i18next.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        textMarginTop: -10,
        ticks: [6],
        fit: false,
        fullGrid: true,
        left: {
          label: '€/MWh',
          tickFormat: function (d) {
            return Math.abs(d);
          },
        },
      },
    };
    //Pie del gráfico PRECIOS DE LOS DESVÍOS EN TIEMPO REAL
    var chart = new Charicharts.Chart(options, parsedData);

    const graphTime = document.querySelector('#mypPrecioWidgetView .mod-date');
    let graphLegend = document.querySelector(
      '#mypPrecioWidgetView .esios-listEnergyItems__list'
    );

    if (parsedData[0].values.length === 0) {
      graphLegend.style.top = '20px';
      graphTime.innerHTML = '00:00';
      graphLegend.innerHTML = '';

      graphLegend.innerHTML = `<div class="row">
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
          <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[0]};"
            ></span>
            <span class="label">${parsedData[0].short_name}</span>
            <div class="value is-number">
              <span class="caption">-</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
         <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[1]};"
            ></span>
            <span class="label">${parsedData[1].short_name}</span>
            <div class="value is-number">
              <span class="caption">-</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
    </div>`;
    } else {
      graphLegend.style.top = '0px';
    }

    chart.on('Trail/moved', function (parsedDataLegend, date) {
      graphTime.innerHTML =
        parsedData[0].values.length === 0
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');

      graphLegend.innerHTML = '';

      graphLegend.innerHTML = `<div class="row">
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
          <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[0]};"
            ></span>
            <span class="label">${parsedDataLegend[0].short_name}</span>
            <div class="value is-number">
              <span class="caption">
              ${
                parsedData[0].values.length === 0
                  ? ''
                  : parsedDataLegend[0].y !== null
                  ? Utils.toNumber(
                      parsedDataLegend[0].y.toFixed(2).toLocaleString(),
                      2,
                      'comma',
                      true
                    )
                  : '-'
              }
            </span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
      <div class="col-xs-4">
        <li
          class="esios-listEnergyItems__item-with-value"
          id="legend-686"
        >
         <div class="esios-listEnergyItems__label">
            <span
              class="esios-listEnergyItems__decorator"
              style="background-color: ${graphColors[1]};"
            ></span>
            <span class="label">${parsedDataLegend[1].short_name}</span>
            <div class="value is-number">
              <span class="caption">${
                parsedData[0].values.length === 0
                  ? ''
                  : parsedDataLegend[1].y !== null
                  ? Utils.toNumber(
                      (parsedDataLegend[1].y >= 0
                        ? parsedDataLegend[1].y
                        : parsedDataLegend[1].y * -1
                      )
                        .toFixed(2)
                        .toLocaleString(),
                      2,
                      'comma',
                      true
                    )
                  : '-'
              }</span
              ><span class="unit">€/MWh</span>
            </div>
          </div>
        </li>
      </div>
    </div>`;
    });

    const dataLength = indicators[0].values.length;
    const rectNoData3 = document.getElementById('mypNoDataRect3');
    const widget3 = document.getElementById('mypGraphic3');
    if (dataLength != 0) {
      widget3.classList.remove('no-data-graphic');
      rectNoData3.classList.add('hidden');
      document
        .querySelector('#mypPrecioWidgetView .esios-listEnergyItems__list')
        .classList.remove('no-data');
    } else {
      widget3.classList.add('no-data-graphic');
      rectNoData3.classList.remove('hidden');
      document
        .querySelector('#mypPrecioWidgetView .esios-listEnergyItems__list')
        .classList.add('no-data');
      document.querySelector('#mypGraphic3 > svg').classList.add('hidden');
      // Modificar el estilo de la caja sin datos para centrar el contenido
      let noDataContainer = document.querySelector('#mypNoDataRect3');
      noDataContainer.style.display = 'flex'; // Asegurar que sea flex
      noDataContainer.style.justifyContent = 'center'; // Centrar horizontalmente
      noDataContainer.style.alignItems = 'center'; // Centrar verticalmente
    }
  };

  const renderFacturationWidget = async (data) => {
    const indicators = data.indicators;
    const graphColors = ['#00A1DA', '#DF4A32'];

    const parsedData = indicators.map((el, index) => {
      return {
        id: el.id,
        alias: el.alias,
        geo_id: el.geo_id,
        short_name: el.short_name,
        type: 'line',
        values: el.values,
        magnitud: el.magnitud,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3.select('#mypFacturacionWidgetView .chari-chart').node(),
      margin: '40 0 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          let { date } = getUrlParams();
          let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
          let formatedCurrentDate = moment(date, 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY');
          if (formatedCurrentDate !== now) {
            return moment(date, 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hours('00')
              .minutes('00')
              .format();
          } else {
            return moment().tz('Europe/Madrid').format();
          }
        },
      },
      xaxis: {
        ticks: [d3.time.hours, 1],
        bottom: {
          label: i18next.t('hours'),
          tickFormat: function (d, i) {
            var date = moment(d).tz('Europe/Madrid');
            var hour = date.hours();
            if (i % 2) {
              return '';
            } else {
              return hour < 10 ? '0' + hour : hour;
            }
          },
        },
      },
      yaxis: {
        fit: false,
        fullGrid: true,
        ticks: [10],
        textMarginTop: -10,
        left: {
          label: '€/MWh',
          tickFormat: function (d, i) {
            if (i) {
              return d;
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#mypFacturacionWidgetView .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#mypFacturacionWidgetView .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-4">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${graphColors[index]};"></span>
                            <span class="label">${el.alias}</span>
                            <div class="value is-number">
                              <span class="caption">-</span><span class="unit">€/MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    } else {
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-4">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label">${el.alias}</span>
                            <div class="value is-number">
                              <span class="caption">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 2, 'comma')
                              }</span><span class="unit">€/MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect4');
    const widget4 = document.getElementById('mypGraphic4');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic4 > svg').classList.add('hidden');
      // Modificar el estilo de la caja sin datos para centrar el contenido
      let noDataContainer = document.querySelector('#mypNoDataRect4');
      noDataContainer.style.display = 'flex'; // Asegurar que sea flex
      noDataContainer.style.justifyContent = 'center'; // Centrar horizontalmente
      noDataContainer.style.alignItems = 'center'; // Centrar verticalmente
      document
        .querySelector('#mypFacturacionWidgetView .esios-listEnergyItems__list')
        .classList.add('no-data');
    } else {
      document
        .querySelector('#mypFacturacionWidgetView .esios-listEnergyItems__list')
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderErniWidget = async (data) => {
    let { date } = getUrlParams();
    // Convertir el string en formato 'dd-mm-yyyy' a un objeto Date
    let parts = date.split('-');
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // Los meses en JS son indexados desde 0 (enero es 0)
    let year = parseInt(parts[2], 10);

    let dateObj = new Date(year, month, day);

    // Obtener el mes y año actuales
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth(); // Mes actual (0 es enero)
    let currentYear = currentDate.getFullYear(); // Año actual

    // Verificar si el mes y año no coinciden con los actuales
    if (
      dateObj.getMonth() !== currentMonth ||
      dateObj.getFullYear() !== currentYear
    ) {
      // Sumar un mes
      dateObj.setMonth(dateObj.getMonth() + 1);
    }

    // Formatear la nueva fecha en 'dd-mm-yyyy'
    let newDay = dateObj.getDate().toString().padStart(2, '0');
    let newMonth = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 para ajustar el índice del mes
    let newYear = dateObj.getFullYear();

    date = `${newDay}-${newMonth}-${newYear}`;
    let isMobile = Utils.isMobileDevice();
    let ruta = window.location.pathname;
    let partes = ruta.split('/');
    let idioma = partes[1];
    let ernitotal = '% Energía renovable no integrable por RRTT Total';
    if (idioma == 'en') {
      ernitotal =
        '% Total Renewable curtailment due to technical constraints in the grid';
    }

    const indicators = data.indicators;
    const graphColors = ['#00A1DA', '#DF4A32'];

    indicators.forEach((indicador) => {
      // Verificar si el id es 10460, 10461 o 10462 y el idioma es 'es'
      switch (indicador.id) {
        case 10460:
          if (idioma == 'es') {
            indicador.name = '% Energía renovable no integrable por RRTT RTT';
          } else {
            indicador.name =
              '% Renewable curtailment due to technical constraints in the transmission grid (RTT)';
          }
          break;
        case 10461:
          if (idioma == 'es') {
            indicador.name = '% Energía renovable no integrable por RRTT RTD';
          } else {
            indicador.name =
              '% Renewable curtailment due to technical constraints in the distribution grid (RTD)';
          }
          break;
        case 10462:
          if (idioma == 'es') {
            indicador.name = '% Energía renovable no integrable por RRTT Total';
          } else {
            indicador.name =
              '% Total Renewable curtailment due to technical constraints in the grid';
          }
          break;
        default:
          break;
      }
    });

    const parsedData = indicators.map((el, index) => {
      return {
        id: el.id,
        alias: el.alias,
        geo_id: el.geo_id,
        short_name: el.short_name,
        values: el.values,
        magnitud: el.magnitud,
        dots: false,
        color: graphColors[index],
      };
    });

    let opts = {
      type: 'multi',
      special: true,
      fit: true,
      multibar: true,
      margin: { top: 20, right: 0, bottom: 40, left: 50 },
      lineInterpolation: 'linear',
      elem: '#mypGraphic5',
      height: isMobile ? 380 : 500,
      date: date,
      yticks: 10,
      left: {
        label: '%',
      },
      bottom: {
        label: 'months',
        prefix: '0',
      },
      magnitud: '%',
    };
    let valh = calculateHoursPerDay(date);
    if (valh !== 25) {
      valh = 24;
    }

    let preData = {
      ...data,
      indicators: data.indicators.map((el) => {
        return {
          ...el,
          values: fillSimpleVals(valh),
        };
      }),
    };

    let dataOrdered = Utils.orderObjectsArrayByKey(
      preData.indicators,
      [10460, 10461, 10462],
      'id'
    );

    const dateObject = moment(date, 'DD-MM-YYYY').toDate(); //Última
    const iniDateObject = moment(dateObject).subtract(14, 'months').toDate(); // Primera

    dataOrdered.map((el, index) => {
      let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;
      let size1;
      if (el.print_values.length === 1) {
        size1 = true;
      }

      let currentIndexValues = 0;
      if (finded.length > 0) {
        let primerFindedDate = moment(finded[0].x).toDate();
        if (iniDateObject < primerFindedDate) {
          let tempIniDateObject = iniDateObject;
          while (tempIniDateObject < primerFindedDate) {
            tempIniDateObject = moment(tempIniDateObject)
              .add(1, 'months')
              .toDate();
            currentIndexValues++;
          }
        }
      }

      let tempIniDateObject = moment(dateObject)
        .subtract(14, 'months')
        .startOf('month')
        .toDate();

      finded.forEach((val) => {
        // Siempre el 1 de cada mes
        let findedDate = moment(val.x).startOf('month').toDate();

        if (findedDate < tempIniDateObject) {
          return;
        }

        el.values[currentIndexValues] = {
          ...el.values[currentIndexValues],
          value: val.y,
          y: val.y,
          color: getColour(index),
          name: el.name != null ? el.name : el.alias,
        };

        currentIndexValues++;
      });
    });

    // Pendiente de agregar linea de saldo total
    preData.indicators.push({
      id: 10462,
      name: ernitotal,
      shortName: '% Energía RRTT Total',
      color: '#9C9C9C',
      legendtype: 'line',
      demanda: true,
      values: fillSimpleVals(valh),
      print_values: fillPrintVals(valh, date),
    });
    preData.indicators.map((elem, index) => {
      if (index === 2) {
        preData.indicators[3].values = preData.indicators[2]?.values.map(
          (el, ind) => {
            return {
              ...el,
              y: el.y + preData.indicators[3]?.values[ind].y,
              value: el.y + preData.indicators[3]?.values[ind].y,
            };
          }
        );
        preData.indicators[3].print_values =
          preData.indicators[2]?.print_values.map((el, index) => {
            if (preData.indicators[3]?.values[index] != null) {
              return {
                ...el,
                y: preData.indicators[3]?.values[index].y,
                value: preData.indicators[3]?.values[index].value,
              };
            }
          });
        // Eliminar el elemento en la posición 2 de preData.indicators
        preData.indicators.splice(2, 1);
      }
    });

    const dataLength = preData.indicators[0].print_values.length;
    const rectNoData5 = document.getElementById('mypNoDataRect5');
    const widget5 = document.getElementById('mypGraphic5');
    if (dataLength != 0) {
      widget5.classList.remove('no-data-graphic');
      rectNoData5.classList.add('hidden');
    } else {
      widget5.classList.add('no-data-graphic');
      rectNoData5.classList.remove('hidden');
      document.querySelector('#mypGraphic5 > svg').classList.add('hidden');
    }
    renderBalanceErniGraph(preData.indicators, i18next.t('month'), opts);
  };

  const fillPrintVals = (rangeNumber, date) => {
    if (date == undefined) {
      date = moment().format('DD-MM-YYYY');
    }
    let arr = [];
    let dateSplit = date?.split('-');
    let utcTime = moment(date, 'DD-MM-YYYY').tz('Europe/Madrid').format();
    for (let i = 0; i < rangeNumber; i++) {
      arr.push({
        datetime:
          `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${
            i < 10 ? '0' + i : i
          }:00:00.000+` + utcTime.split('+')[1],
        datetime_utc: '2022-02-04T23:00:00Z',
        geo_id: null,
        geo_name: null,
        tz_time: '2022-02-04T23:00:00.000Z',
        value: 0,
      });
    }
    return arr;
  };

  const fillSimpleVals = (rangeNumber) => {
    let arr = [];
    for (let i = 0; i < 14; i++) {
      arr.push({
        x: i,
        order: i,
        y: null,
        value: null,
        color: '#333333',
        name: '',
      });
    }
    return arr;
  };
  return {
    init: init,
    renderVolumeGraph: (data) => renderVolumeGraph(data),
    renderMoreDataWidget: (data) => renderMoreDataWidget(data),
    renderUnitaryCostGraph: (data) => renderUnitaryCostGraph(data),
    renderDesviosWidget: (data) => renderDesviosWidget(data),
    renderFacturationWidget: (data) => renderFacturationWidget(data),
    renderErniWidget: (data) => renderErniWidget(data),
  };
})();

export { renderMercadosPrecios };

export const processValues = (rangeNumber, arreglo, date, negative = false) => {
  let totalHours = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
  if (rangeNumber == arreglo.length) {
    totalHours = rangeNumber;
  }
  if (totalHours == arreglo.length) {
    let newArr = [];

    arreglo.forEach((value) => {
      let newY = value.y;
      if (negative) {
        newY = value.y == 0 ? 0 : value.y == null ? null : value.y * -1;
      }
      newArr = [...newArr, { x: value.x, y: newY, z: 'prueba' }];
    });
    return newArr;
  } else {
    let arr = [];
    let dateSplit = date.split('-');
    for (let i = 0; i < rangeNumber; i++) {
      let x = new Date(
        `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${
          i < 10 ? '0' + i : i
        }:00:00`
      );

      arr.push({
        x,
        y: null,
      });
    }
    arreglo.filter((el) => {
      let index = parseInt(moment(el.x).tz('Europe/Madrid').format('HH'));
      let yval = 0;
      if (negative) {
        yval = el.y === 0 ? 0 : el.y === null ? null : el.y * -1;
      }

      arr[index] = {
        x: new Date(el.x),
        y: negative ? yval : el.y,
      };
    });
    arr.map((el) => {
      if (el.y === null) {
        let index = parseInt(new Date(el.x).getHours());

        arr[index] = {
          ...el,
          x: el.x,
        };
      }
    });
    return arr;
  }
};
