'use strict';

/**
 * @description Mercados y precios Component
 * @returns {Object} Devuelve la instancia del componente
 */
import i18next from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { componentTabs } from '../../shared/components/tabs/tabs.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import {
  setMyPChangeUnitaryCostHour,
  setMyPChangeVolumeHour,
} from '../../state/actions.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { servicesIntercambiosInternacionales } from '../intercambios-internacionales/intercambiosInternacionales.services.js';
import { intercambiosInternacionalesContent } from '../intercambios-internacionales/intercambiosInternacionalesContent.js';
import { servicesMercadosPrecios } from './mercadosPrecios.services';
import { templateMercadosPrecios } from './mercadosPrecios.template';
import { mercadosPreciosContent } from './mercadosPreciosContent.js';
import { renderMercadosPrecios } from './mercadosPreciosRenders';

let componentMercadosPrecios = (function () {
  let currentDate = moment().tz('Europe/Madrid').format('YYYY-MM-DD');
  let formatDate = currentDate.split('-');
  let fecha = {
    day: formatDate[2],
    month: formatDate[1],
    year: formatDate[0],
  };
  let volumenUTC1 = false;
  let costeUTC1 = false;
  let context = {};
  let handlers = {};
  let newDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');

  let volumeData;
  let unitarycostData;
  let auctionsData;
  let auctionsPortugalData;
  let desviosData;
  let facturationData;
  let pageTitle = '';
  let date = moment().tz('Europe/Madrid');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let renderSuccess = false;
  let objMarket = [];
  let objToolbar = [];
  let documents;

  let buttons;
  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];

  // select_volumen-widget
  const selectTimeForVolume = {
    id: 'select_volumen',
    datetimeFor: 'volumeTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (hours, minutes) => changeTime(hours, minutes),
  };

  // select_cost-widget
  const selectTimeForCost = {
    id: 'select_cost',
    datetimeFor: 'volumeTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (value) => changeTime(value),
  };

  const changeTime = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    if (id === selectTimeForCost.id) {
      if (hours > 2) {
        costeUTC1 = true;
      } else if (hours < 2) {
        costeUTC1 = false;
      }
      store.dispatch(setMyPChangeUnitaryCostHour(`${hours}:00`));
      renderFinalPriceEnergy();
    } else if (id === selectTimeForVolume.id) {
      if (hours > 2) {
        volumenUTC1 = true;
      } else if (hours < 2) {
        volumenUTC1 = false;
      }
      store.dispatch(setMyPChangeVolumeHour(`${hours}:00`));
      renderMarketProduction();
    }
  };

  let spinnerNone = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'none';
  };

  let spinnerTrue = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'flex';
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = async () => {
    const state = store.getState();
    document.title = `${i18next.t('markets_and_prices')} | ${i18next.t(
      'page_subtitle'
    )}`;

    buttons = [
      {
        label: 'see_more_doc',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}?scope=${i18next.t('markets_and_prices').replaceAll(' ', '+')}`,
      },
      {
        label: 'downloads_files',
        url: `/${i18next.t('routes.lng')}/${i18next.t('routes.downloads')}`,
      },
    ];
    documents = [
      {
        title: 'exchange_of_information_with_the_OS',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/intercambio-de-informacion-con-el-os-liquidaciones`,
      },
      {
        title: 'resolution_of_technical_restrictions',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/p-o-3-2-resolucion-de-restricciones-tecnicas`,
      },
      {
        title: 'resolution_of_tertiary_regulation',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/p-o-7-3-regulacion-terciaria`,
      },
      {
        title: 'royal_decree',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/real-decreto-216-2014-metodologia-calculo-precios-voluntarios-pequeno-consumidor`,
      },
    ];

    let volumeTime = state.exampleReducer.pages.myp.volumeHour;
    selectTimeForVolume.currentHour = volumeTime.split(':')[0];
    selectTimeForVolume.currentMinutes = '00';

    let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;

    selectTimeForCost.currentHour = unitaryCostTime.split(':')[0];
    selectTimeForCost.currentMinutes = '00';

    // let tableTime = state.exampleReducer.pages.myp.tableHour;
    // selectTimeForTable.currentHour = tableTime.split(':')[0];
    // selectTimeForTable.currentMinutes = '00';
  };

  let renderComponent = async () => {
    const state = store.getState();
    let currDate = getUrlParams().date || moment().format('DD-MM-YYYY');
    let monthsAfter = moment(currDate, 'DD-MM-YYYY')
      .subtract(13, 'months')
      .format('DD-MM-YYYY');
    let dayBefore = moment(currDate, 'DD-MM-YYYY')
      .subtract('1', 'day')
      .format('DD-MM-YYYY');
    objToolbar = [
      {
        id: 1,
        slug: i18next.t('routes.scheduling-in-the-production-market'),
        info: true,
        share: true,
        favorite: true,
        favId: 6,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10057?compare_indicators=602,605,606,607,608,609,610,611,666,667,674,675,680,681,701,702,720,721,1442&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 2,
        slug: i18next.t('routes.energy-final-price'),
        info: true,
        share: true,
        favorite: true,
        favId: 7,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10211?compare_indicators=805,806,807,808,809,810,811,812,813,814,815,816,1277,10403,1286,1368&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 3,
        slug: i18next.t('routes.real-time-imbalances-price'),
        info: true,
        share: true,
        favorite: true,
        favId: 8,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/686?vis=1&start_date=${currDate}T00%3A00&end_date=${currDate}T23%3A55&compare_start_date=${dayBefore}T00%3A00&compare_indicators=687`,
      },
      {
        id: 4,
        slug: i18next.t('routes.active-energy-invoicing-price-pvpc'),
        info: true,
        share: true,
        favorite: true,
        favId: 9,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/1001?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 5,
        slug: i18next.t(
          'routes.percentage-of-renewable-energy-that-cannot-be-integrated'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 42,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10462?compare_indicators=10460,10461&start_date=${monthsAfter}T00%3A00&end_date=${currDate}T23%3A55&geoids=`,
      },
      {
        id: 6,
        slug: i18next.t(
          'routes.percentage-of-renewable-energy-that-cannot-be-integrated'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 42,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10462?compare_indicators=10460,10461&start_date=${monthsAfter}T00%3A00&end_date=${currDate}T23%3A55&geoids=`,
      },
    ];

    let listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.programming_units'
        )}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.physical_units'
        )}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.market_subjects'
        )}`,
      },
    ];

    let renderTable = async () => {
      fecha.day = moment($('#datepicker').val(), 'DD / MM / YYYY').format('DD');
      fecha.month = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'MM'
      );
      fecha.year = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'YYYY'
      );

      let currentHour = moment().tz('Europe/Madrid').format('HH');
      let currentMinutes = moment().tz('Europe/Madrid').format('mm');
      auctionsData =
        await servicesIntercambiosInternacionales.getAuctionsDataFromWidget({
          lang: state.exampleReducer.currentLang,
          time: `${currentHour}:${currentMinutes}`,
          ...fecha,
        });

      auctionsPortugalData = await servicesIntercambiosInternacionales
        .getAuctionsPortugalDataFromWidget({
          lang: state.exampleReducer.currentLang,
          time: `${currentHour}:${currentMinutes}`,
          ...fecha,
        })
        .then((data) => {
          spinnerNone('spinner10');
          return data;
        });
      const tableData = auctionsData.indicators;
      const newTable = tableData.concat(auctionsPortugalData.indicators);
      setInfoModal(
        4,
        {
          ...auctionsData,
          indicators: newTable,
        },
        templateShareModal(
          'percentage-of-renewable-energy-that-cannot-be-integrated',
          window.location.search
        ),
        'all'
      );
      let miArrayTab =
        intercambiosInternacionalesContent.tableContent(newTable);
      let tableContent1 = document.getElementById('tab-myp-content1');
      tableContent1.innerHTML = miArrayTab.tabsContent[0].content;
      let tableContent2 = document.getElementById('tab-myp-content2');
      tableContent2.innerHTML = miArrayTab.tabsContent[1].content;
      let tableContent3 = document.getElementById('tab-myp-content3');
      tableContent3.innerHTML = miArrayTab.tabsContent[2].content;
      let tableContent4 = document.getElementById('tab-myp-content4');
      tableContent4.innerHTML = miArrayTab.tabsContent[3].content;

      componentTabs.render(miArrayTab, '#tabwithcontent');
    };

    const updateChars = async (currentDay = true) => {
      const inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');
      if (currentDay) {
        date = moment().tz('Europe/Madrid').format('DD/MM/YYYY');
      } else {
        date = $('#datepicker').val();
      }

      let datepickerDate = date.split('/');
      fecha.day = datepickerDate[0];
      fecha.month = datepickerDate[1];
      fecha.year = datepickerDate[2];

      setParameter('date', moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY'));

      // NEW OBJTOOLBAR
      let currDate = getUrlParams().date || moment().format('DD-MM-YYYY');
      let monthsAfter = moment(currDate, 'DD-MM-YYYY')
        .subtract(13, 'months')
        .format('DD-MM-YYYY');
      let dayBefore = moment(currDate, 'DD-MM-YYYY')
        .subtract('1', 'day')
        .format('DD-MM-YYYY');
      objToolbar = [
        {
          id: 1,
          slug: i18next.t('routes.scheduling-in-the-production-market'),
          info: true,
          share: true,
          favorite: true,
          favId: 6,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10057?compare_indicators=602,605,606,607,608,609,610,611,666,667,674,675,680,681,701,702,720,721,1442&start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 2,
          slug: i18next.t('routes.energy-final-price'),
          info: true,
          share: true,
          favorite: true,
          favId: 7,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10211?compare_indicators=805,806,807,808,809,810,811,812,813,814,815,816,1277,1286,1368&start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 3,
          slug: i18next.t('routes.real-time-imbalances-price'),
          info: true,
          share: true,
          favorite: true,
          favId: 8,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/686?vis=1&start_date=${currDate}T00%3A00&end_date=${currDate}T23%3A55&compare_start_date=${dayBefore}T00%3A00&compare_indicators=687`,
        },
        {
          id: 4,
          slug: i18next.t('routes.active-energy-invoicing-price-pvpc'),
          info: true,
          share: true,
          favorite: true,
          favId: 9,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1001?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 5,
          slug: i18next.t(
            'routes.percentage-of-renewable-energy-that-cannot-be-integrated'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 42,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1001?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 6,
          slug: i18next.t(
            'routes.percentage-of-renewable-energy-that-cannot-be-integrated'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 10,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10462?compare_indicators=10460,10461&start_date=${monthsAfter}T00%3A00&end_date=${currDate}T23%3A55&geoids=`,
        },
      ];
      let shareModalObj = [
        {
          id: 0,
          slug: 'scheduling-in-the-production-market',
          needSlug: false,
          size: {
            height: 565,
            width: '100%',
          },
        },
        {
          id: 1,
          slug: 'energy-final-price',
        },
        {
          id: 2,
          slug: 'real-time-imbalances-price',
          needSlug: false,
          size: {
            height: 596,
            width: '100%',
          },
        },
        {
          id: 3,
          slug: 'active-energy-invoicing-price-pvpc',
        },
        {
          id: 4,
          slug: 'explicit-auctions-results',
        },
        {
          id: 5,
          slug: 'percentage-of-renewable-energy-that-cannot-be-integrated',
          needSlug: false,
          size: {
            height: 596,
            width: '100%',
          },
        },
      ];

      const ShowSpinner = (idRect, idSpinner) => {
        document.getElementById(idRect).classList.add('hidden');
        document.getElementById(idRect).style.display = 'flex';
      };

      ShowSpinner('mypNoDataRect1', 'spinner6');
      ShowSpinner('mypNoDataRect2', 'spinner7');
      ShowSpinner('mypNoDataRect3', 'spinner8');
      ShowSpinner('mypNoDataRect4', 'spinner9');
      ShowSpinner('mypNoDataRect5', 'spinner42');
      ShowSpinner('mypNoDataRect4', 'spinner10');

      shareModalObj.forEach((elemnt) => {
        if (elemnt.size) {
          setInfoModal(
            elemnt.id,
            null,
            templateShareModal(
              elemnt.slug,
              window.location.search,
              elemnt.needSlug,
              elemnt.size
            ),
            'share'
          );
        } else {
          setInfoModal(
            elemnt.id,
            null,
            templateShareModal(elemnt.slug, window.location.search),
            'share'
          );
        }
      });
      await renderVolumeGraphAgain();
      await renderUnitaryCostAgain();
      await renderFacturacionGraphAgain();
      await renderDesvioGraphAgain();
      await renderErniGraphAgain();
      // await renderTable();

      inputCalendar.removeAttribute('disabled');
      divCalendar.classList.remove('disabled');
      return inputCalendar.value;
    };

    try {
      let loaded = {
        6: false,
        7: false,
        8: false,
        9: false,
      };

      const loadedCheck = (id) => {
        loaded[id] = true;
        let allLoaded = Object.values(loaded).some(
          (isLoaded) => isLoaded == false
        );

        if (!allLoaded) {
          let inputCalendar = document.querySelector('#datepicker');
          let divCalendar = document.querySelector('.is-day-selectable');
          inputCalendar.removeAttribute('disabled');
          divCalendar.classList.remove('disabled');
        }
      };

      templateMercadosPrecios(listMarket, documents, lang, buttons);

      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');

      const RapidAccessData = [
        {
          id: '.mypVolumenWidgetView',
          title: 'programming_in_Production_Market',
        },
        {
          id: '.mypCosteWidgetView',
          title: 'energy_final_price',
        },
        {
          id: '.mypPrecioWidgetView',
          title: 'deviations_cost_in_real_time',
        },
        {
          id: '.mypFacturacionWidgetView',
          title: 'pvpc_highlight',
        },
        {
          id: '.mypErniWidgetView',
          title: 'erni',
        },
        {
          id: '.esios-layout__subcontent',
          title: 'more',
        },
      ];
      componentRapidAccess.render(RapidAccessData);

      if (urlParams.get('date')) {
        $('#datepicker').datepicker(
          'setDate',
          moment(urlParams.get('date'), 'DD-MM-YYYY').format('DD / MM / YYYY')
        );
      } else {
        setParameter(
          'date',
          moment(document.getElementById('datepicker').value, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY')
        );
        $('#datepicker').datepicker(
          'setDate',
          moment(document.getElementById('datepicker').value, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .format('DD / MM / YYYY')
        );
      }

      let appDate = moment(urlParams.get('date'), 'DD-MM-YYYY').format(
        'DD-MM-YYYY'
      );
      let todayDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
      let volumeTime = state.exampleReducer.pages.myp.volumeHour;
      selectTimeForVolume.currentHour =
        appDate === todayDate ? volumeTime.split(':')[0] : '00';
      selectTimeForVolume.currentMinutes = '00';
      let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;
      selectTimeForCost.currentHour =
        appDate === todayDate ? unitaryCostTime.split(':')[0] : '00';
      selectTimeForCost.currentMinutes = '00';

      $(document).on('change', '#datepicker', () => {
        updateChars(false);
      });

      Utils.clickHoy('datepicker', updateChars, true);

      let currentDate = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      fecha.day = moment($('#datepicker').val(), 'DD / MM / YYYY').format('DD');
      fecha.month = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'MM'
      );
      fecha.year = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'YYYY'
      );

      servicesMercadosPrecios
        .getVolumenDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: Utils.datesMatch(currentDate)
            ? state.exampleReducer
            : '00:00',
          ...fecha,
        })
        .then((data) => {
          spinnerNone('spinner6', 'mypVolumenWidgetView');
          if (data.indicators[0]?.values.length === 0) {
            selectTimeForVolume.currentHour = '00';
            selectTimeForVolume.currentMinutes = '00';
          }
          componentTimeSelector.render(
            selectTimeForVolume,
            '#mypVolumenWidgetViewSelector'
          );
          setInfoModal(
            0,
            data,
            templateShareModal(
              'scheduling-in-the-production-market',
              window.location.search,
              false,
              {
                height: 565,
                width: '100%',
              }
            ),
            'all'
          );
          renderMercadosPrecios.renderVolumeGraph(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget6 svg').remove();
            document.querySelector('#widget6 .volume-leyends ul').remove();
            renderMercadosPrecios.renderVolumeGraph(data);
          });
        })
        .finally(() => {
          loadedCheck(6);
        });

      servicesMercadosPrecios
        .getUnitaryCostDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: Utils.datesMatch(currentDate)
            ? state.exampleReducer
            : '00:00',
          ...fecha,
        })
        .then((data) => {
          spinnerNone('spinner7', 'mypCosteWidgetViewSelector');
          if (data.indicators[0]?.values.length === 0) {
            selectTimeForCost.currentHour = '00';
            selectTimeForCost.currentMinutes = '00';
          }
          componentTimeSelector.render(
            selectTimeForCost,
            '#mypCosteWidgetViewSelector'
          );
          setInfoModal(
            1,
            data,
            templateShareModal('energy-final-price', window.location.search),
            'all'
          );
          renderMercadosPrecios.renderUnitaryCostGraph(data);
        })
        .finally(() => {
          loadedCheck(7);
        });

      servicesMercadosPrecios
        .getDesvioDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: Utils.datesMatch(currentDate)
            ? state.exampleReducer
            : '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget8').style.display = 'block';
          spinnerNone('spinner8', 'mypPrecioWidgetView');
          setInfoModal(
            2,
            data,
            templateShareModal(
              'real-time-imbalances-price',
              window.location.search,
              false,
              {
                height: 596,
                width: '100%',
              }
            ),
            'all'
          );
          renderMercadosPrecios.renderDesviosWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget8 svg').remove();
            renderMercadosPrecios.renderDesviosWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(8);
        });

      servicesMercadosPrecios
        .getFacturationDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: Utils.datesMatch(currentDate)
            ? state.exampleReducer
            : '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget9').style.display = 'block';
          spinnerNone('spinner9', 'mypFacturacionWidgetView');
          setInfoModal(
            3,
            data,
            templateShareModal(
              'active-energy-invoicing-price-pvpc',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );
          renderMercadosPrecios.renderFacturationWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget9 svg').remove();
            renderMercadosPrecios.renderFacturationWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getErniDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: Utils.datesMatch(currentDate)
            ? state.exampleReducer
            : '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget42').style.display = 'block';
          spinnerNone('spinner42', 'mypErniWidgetView');
          setInfoModal(
            5,
            data,
            templateShareModal(
              'percentage-of-renewable-energy-that-cannot-be-integrated',
              window.location.search,
              false,
              {
                height: 414,
                width: '100%',
              }
            ),
            'all'
          );
          renderMercadosPrecios.renderErniWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget42 svg').remove();
            renderMercadosPrecios.renderErniWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      let moreData = await servicesMercadosPrecios.getMoreData({
        lang: state.exampleReducer.currentLang,
      });
      renderMercadosPrecios.renderMoreDataWidget(moreData);
      componentTimeSelector.render(
        selectTimeForVolume,
        '#mypVolumenWidgetViewSelector'
      );
      componentTimeSelector.render(
        selectTimeForCost,
        '#mypCosteWidgetViewSelector'
      );

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };

      componentDocumentList.render(newDLArray, '#document-list-myp');

      // renderTable();

      let modalContainer = document.getElementById('myp__modals');
      document.querySelectorAll('.share__modal').forEach((el) => {
        modalContainer.append(el);
      });
      document
        .querySelectorAll('.esios-market-prices__container .share__modal')
        .forEach((el) => {
          el.remove();
        });
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    document
      .querySelector('.esios-layout__wrap')
      ?.classList.add('esios-layout__wrap--mercados-y-precios');
    document
      .querySelector('.esios-layout__breadcrumbs')
      ?.classList.add('esios-layout__breadcrumbs--myp');
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      objToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    objToolbar[id].widgetData = data;
    objToolbar[id].shareModal = html;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1), false);
  };

  let postRenderComponent = () => {
    let tabs = document.querySelectorAll('.ui-tabs-anchor');

    for (let tab of tabs) {
      tab.addEventListener('click', () => {
        let button = document.getElementById('calendar-button');
        let value = tab.innerHTML.trim();

        if (value.includes('FR')) {
          button.hash = '#fra';
        } else {
          button.hash = '#por';
        }
      });
    }
  };

  let init = async () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    await renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;
    let documentListData = await servicesMercadosPrecios.getArchives(
      filterForm
    );

    let newDLArray = {
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
    };
    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let renderVolumeGraphAgain = async () => {
    const state = store.getState();
    let volumeTime = state.exampleReducer.pages.myp.volumeHour;
    selectTimeForVolume.currentHour = '00';
    selectTimeForVolume.currentMinutes = '00';
    try {
      spinnerTrue('spinner6', 'mypVolumenWidgetView');
      volumeData = await servicesMercadosPrecios
        .getVolumenDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget6').style.display = 'block';
          spinnerNone('spinner6', 'mypVolumenWidgetView');
          document.querySelector('#mypVolumenWidgetView svg').remove();
          document
            .querySelector('#mypVolumenWidgetView .esios-listEnergyItems__list')
            .remove();
          renderMercadosPrecios.renderVolumeGraph(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget6 svg')?.remove();
            document.querySelector('#widget6 .volume-leyends ul')?.remove();

            renderMercadosPrecios.renderVolumeGraph(data);
          });
          return data;
        });
      setInfoModal(
        0,
        volumeData,
        templateShareModal(
          'scheduling-in-the-production-market',
          window.location.search,
          false,
          {
            height: 565,
            width: '100%',
          }
        ),
        'share'
      );
      componentTimeSelector.render(
        selectTimeForVolume,
        '#mypVolumenWidgetViewSelector'
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  // Render when time-selector change the value
  let renderMarketProduction = async () => {
    const state = store.getState();

    let volumeTime = state.exampleReducer.pages.myp.volumeHour;
    selectTimeForVolume.currentHour = volumeTime.split(':')[0];
    selectTimeForVolume.currentMinutes = volumeTime.split(':')[1];

    try {
      spinnerTrue('spinner6', 'mypVolumenWidgetView');
      volumeData = await servicesMercadosPrecios
        .getVolumenDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: volumeTime,
          ...fecha,
          volumenUTC1,
        })
        .then((data) => {
          spinnerNone('spinner6', 'mypVolumenWidgetView');
          setInfoModal(
            0,
            data,
            templateShareModal(
              'scheduling-in-the-production-market',
              window.location.search,
              false,
              {
                height: 565,
                width: '100%',
              }
            ),
            'share'
          );
          document.querySelector('#mypVolumenWidgetView svg').remove();
          document
            .querySelector('#mypVolumenWidgetView .esios-listEnergyItems__list')
            .remove();
          renderMercadosPrecios.renderVolumeGraph(data);
        });

      componentTimeSelector.render(
        selectTimeForVolume,
        '#mypVolumenWidgetViewSelector'
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderUnitaryCostAgain = async () => {
    const state = store.getState();

    let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner7', 'mypCosteWidgetView');
      unitarycostData = await servicesMercadosPrecios
        .getUnitaryCostDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget7').style.display = 'flex';
          spinnerNone('spinner7', 'mypCosteWidgetView');
          setInfoModal(
            1,
            data,
            templateShareModal('energy-final-price', window.location.search),
            'share'
          );
          document.querySelector('#mypCosteWidgetView svg').remove();
          document.querySelector('.informativeWindow').remove();
          document
            .querySelector('#mypCosteWidgetView .esios-listEnergyItems__list')
            .remove();
          renderMercadosPrecios.renderUnitaryCostGraph(data);
          return data;
        });

      setInfoModal(
        1,
        unitarycostData,
        templateShareModal('energy-final-price', window.location.search),
        'share'
      );

      componentTimeSelector.render(
        selectTimeForCost,
        '#mypCosteWidgetViewSelector'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  // Render when time-selector change the value
  let renderFinalPriceEnergy = async () => {
    const state = store.getState();

    let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;
    selectTimeForCost.currentHour = unitaryCostTime.split(':')[0];
    selectTimeForCost.currentMinutes = unitaryCostTime.split(':')[1];

    try {
      spinnerTrue('spinner7', 'mypCosteWidgetView');
      unitarycostData = await servicesMercadosPrecios
        .getUnitaryCostDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: unitaryCostTime,
          ...fecha,
          costeUTC1,
        })
        .then((data) => {
          spinnerNone('spinner7', 'mypCosteWidgetView');
          document.querySelector('#mypCosteWidgetView svg').remove();
          document.querySelector('.informativeWindow').remove();
          document
            .querySelector('#mypCosteWidgetView .esios-listEnergyItems__list')
            .remove();
          renderMercadosPrecios.renderUnitaryCostGraph(data);
        });

      componentTimeSelector.render(
        selectTimeForCost,
        '#mypCosteWidgetViewSelector'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDesvioGraphAgain = async () => {
    const state = store.getState();

    try {
      spinnerTrue('spinner8', 'mypPrecioWidgetView');
      desviosData = await servicesMercadosPrecios
        .getDesvioDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget8').style.display = 'block';
          spinnerNone('spinner8', 'mypPrecioWidgetView');
          document.querySelector('#mypPrecioWidgetView svg').remove();
          renderMercadosPrecios.renderDesviosWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget8 svg').remove();
            renderMercadosPrecios.renderDesviosWidget(data);
          });
          return data;
        });

      setInfoModal(
        2,
        desviosData,
        templateShareModal(
          'real-time-imbalances-price',
          window.location.search,
          false,
          {
            height: 596,
            width: '100%',
          }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderFacturacionGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner9', 'mypFacturacionWidgetView');
      facturationData = await servicesMercadosPrecios
        .getFacturationDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget9').style.display = 'block';
          spinnerNone('spinner9', 'mypFacturacionWidgetView');
          document.querySelector('#mypFacturacionWidgetView svg').remove();
          renderMercadosPrecios.renderFacturationWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget9 svg').remove();
            renderMercadosPrecios.renderFacturationWidget(data);
          });
          return data;
        });

      setInfoModal(
        3,
        facturationData,
        templateShareModal(
          'active-energy-invoicing-price-pvpc',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderErniGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner42', 'mypErniWidgetView');
      facturationData = await servicesMercadosPrecios
        .getErniDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget42').style.display = 'block';
          spinnerNone('spinner42', 'mypErniWidgetView');
          document.querySelector('#mypErniWidgetView svg') != null
            ? document.querySelector('#mypErniWidgetView svg').remove()
            : '';

          renderMercadosPrecios.renderErniWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget42 svg').remove();
            renderMercadosPrecios.renderErniWidget(data);
          });
          return data;
        });

      setInfoModal(
        5,
        facturationData,
        templateShareModal(
          'percentage-of-renewable-energy-that-cannot-be-integrated',
          window.location.search,
          false,
          {
            height: 614,
            width: '100%',
          }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  return {
    render: init,
  };
})();

export { componentMercadosPrecios };
