'use strict';

/**
 * @description Pvpc component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import moment from 'moment';
import { componentModal } from '../../shared/components/modal/modal.component.js';
import { setUrlParam } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { templateModalDownload } from './pvpc.modal-download.template.js';
import { servicespvpc } from './pvpc.services.js';
import { templatepvpc } from './pvpc.template.js';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const colorIndicator = {
  isGreen: function (v) {
    return v <= 100;
  },
  isYellow: function (v) {
    return v > 100 && v <= 150;
  },
  isRed: function (v) {
    return v > 150;
  },
};
const colorIndicatorHex = ['#D7F9D7', '#FFF2CA', '#FEC5C5'];

let maxDate = moment().tz('Europe/Madrid').format('DD / MM / YYYY');

let componentpvpc = (function () {
  let renderSuccess = false;
  let currentDatetime = '';

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = () => {
    document.title = `${i18n.t('pvpc')} | ${i18n.t('page_subtitle')}`;

    if (urlParams.get('date')) {
      $('#datepicker').val(
        moment(urlParams.get('date'), 'DD-MM-YYYY').format('DD / MM / YYYY')
      );
    }
  };

  let renderComponent = async () => {
    try {
      templatepvpc();
      generateSelect();
      document.getElementById('selectYear').setAttribute('val', '1');
      document.getElementById('datepicker').addEventListener('click', () => {
        document
          .querySelector('#ui-datepicker-div .ui-datepicker-current')
          .addEventListener('click', () => {
            setParameter(
              'date',
              moment().tz('Europe/Madrid').format('DD-MM-YYYY')
            );
            getAllData(moment().tz('Europe/Madrid').format('YYYY-MM-DD'));
          });
      });

      componentModal.render(
        templateModalDownload(modalDownload),
        'buttonModalDownload',
        'modal'
      );

      componentModal.render(
        i18n.t('pvpc_infowindow'),
        'buttonModalInfo',
        'modalInfo'
      );

      if (!urlParams.get('date')) {
        $('#datepicker').val(
          moment().tz('Europe/Madrid').format('DD / MM / YYYY')
        );
      } else {
        $('#datepicker').val(
          moment(urlParams.get('date'), 'DD-MM-YYYY')
            .tz('Europe/Madrid')
            .format('DD / MM / YYYY')
        );
      }

      $('#datepicker').datepicker('option', 'maxDate', maxDate);

      let formatedToday = moment(
        $('#datepicker').val(),
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      changeLegend('kWh');
      const calendar = document.querySelector('#datepicker');
      currentDatetime = calendar.value;
      $(document).on('change', '#datepicker', async () => {
        // document.getElementById('selectUnit').value = 'kWh';
        dataPVPC.unit = 'MWh';
        // $('#selectUnit').val('1').selectmenu('refresh');
        document
          .getElementById('selectYear')
          .setAttribute(
            'val',
            $('#selectUnit').val() ||
              document.getElementById('selectYear').getAttribute('val')
          );
        currentDatetime = $('#datepicker').val();
        let date = moment($('#datepicker').val(), 'DD/MM/YYYY');
        let formatDate = moment(date).format('YYYY-MM-DD');

        templatepvpc(formatDate);
        setParameter(
          'date',
          moment(formatDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
        );
        renderModalDownload(currentDatetime);
        getAllData(formatDate);

        window.addEventListener('resize', () => {
          update();
        });
      });
      document.querySelector('.prev-btn').addEventListener('click', () => {
        document
          .getElementById('selectYear')
          .setAttribute(
            'val',
            $('#selectUnit').val() ||
              document.getElementById('selectYear').getAttribute('val')
          );

        let date = document
          .getElementById('datepicker')
          .value.replaceAll(' ', '')
          .split('/');
        let [d, m, y] = date;
        let formatDate = moment(`${y}-${m}-${d}`, 'YYYY-MM-DD')
          .tz('Europe/Madrid')
          .subtract('1', 'day')
          .format('YYYY-MM-DD');
        templatepvpc(formatDate);
      });
      document.querySelector('.next-btn').addEventListener('click', () => {
        document
          .getElementById('selectYear')
          .setAttribute(
            'val',
            $('#selectUnit').val() ||
              document.getElementById('selectYear').getAttribute('val')
          );

        let date = document
          .getElementById('datepicker')
          .value.replaceAll(' ', '')
          .split('/');
        let [d, m, y] = date;
        let formatDate = moment(`${y}-${m}-${d}`, 'YYYY-MM-DD')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD');

        if (!moment(maxDate).isSame(formatDate)) {
          templatepvpc(formatDate);
        }
      });
      if (urlParams.get('date')) {
        getAllData(
          moment(urlParams.get('date'), 'DD-MM-YYYY').format('YYYY-MM-DD')
        );
      } else {
        getAllData(
          moment(formatedToday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          true
        );
      }

      renderModalDownload(currentDatetime);
      let prevCalendarButton = document.querySelector(
        '.esios-layout__breadcrumbs__calendar__change-datetime.prev-btn'
      );
      let nextCalendarButton = document.querySelector(
        '.esios-layout__breadcrumbs__calendar__change-datetime.next-btn'
      );

      prevCalendarButton.addEventListener('click', () => {
        calendar.value = moment(calendar.value, 'DD / MM / YYYY')
          .subtract('1', 'day')
          .format('DD / MM / YYYY');
        let date = moment(calendar.value, 'DD / MM / YYYY');
        let formatDate = moment(date).format('YYYY-MM-DD');
        setParameter(
          'date',
          moment(formatDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
        );
        getAllData(formatDate);
      });
      nextCalendarButton.addEventListener('click', () => {
        let nextDay = moment(calendar.value, 'DD / MM / YYYY')
          .add('1', 'day')
          .format('DD / MM / YYYY');

        if (
          moment(maxDate, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .add('1', 'day')
            .format('DD / MM / YYYY') !== nextDay
        ) {
          calendar.value = nextDay;
          let date = moment(calendar.value, 'DD / MM / YYYY');
          let formatDate = moment(date).format('YYYY-MM-DD');
          setParameter(
            'date',
            moment(formatDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
          );
          getAllData(formatDate);
        }
      });
      $.datepicker._gotoToday = function (id) {
        $(id).datepicker('setDate', new Date()).datepicker('hide').blur();
        let formattedDate = moment(new Date()).format('YYYY-MM-DD');

        // Update the datepicker value
        document.getElementById('datepicker').value = moment(new Date()).format(
          'DD / MM / YYYY'
        );

        // Trigger the template update with the new date
        templatepvpc(formattedDate);

        // Update the URL parameter and fetch data
        setParameter(
          'date',
          moment(formattedDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
        );
        getAllData(formattedDate);
      };
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.esios-layout__breadcrumbs__wrapper')
      .classList.add('esios-layout__breadcrumbs__wrapper--pvpc');
    document
      .querySelector('.esios-layout__breadcrumbs')
      .classList.add('esios-layout__breadcrumbs--pvpc');
    document
      .querySelector('.esios-layout__wrap')
      .classList.add('esios-layout__wrap--pvpc');
    document
      .querySelector('.esios-layout__breadcrumbs__container')
      .classList.add('breadcrumbs-pvpc');
    document
      .querySelector('.esios-period-selector')
      .classList.add('unit-select-pvpc');
    document
      .querySelector('.esios-layout__breadcrumbs__calendar-container')
      .classList.add('calendar-select-pvpc');
    document
      .querySelector('.mod-date-days')
      .classList.add('mod-date-days-pvpc');
  };

  let postRenderComponent = () => {};

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentpvpc };

const legend = {
  kWh: ['< 0,10 €/kWh', '0,10 - 0,15 €/kWh', '> 0,15 €/kWh'],
  MWh: ['< 100 €/MWh', '100 - 150 €/MWh', '> 150 €/MWh'],
};

let dataPVPC = { values: {}, unit: 'MWh' };
let dataPCB = { values: {} };
let dataCYM = { values: {} };
let dataGEN = { values: {} };
let dataNOC = { values: {} };
let dataVHC = { values: {} };
let mercadosTAHPCB = true;
let mercadosTAHCYM = true;

const getAllData = async (day, firstLoad = false) => {
  document.querySelector('.esios-pvpc__data-pvpc')?.classList.add('hidden');

  showNoData(false);
  remove();
  showSpinner(true);
  servicespvpc
    .getIndicatorOfDay(
      {
        PCB: [],
        CYM: [],
        PMHPCB: [],
        TAHPCB: [],
        SAHPCB: [],
        FOSPCB: [],
        FOMPCB: [],
        CCVPCB: [],
        TEUPCB: [],
        PCAPPCB: [],
        EDSRPCB: [],
        EDCGASPCB: [],
        INTPCB: [],
        PMHCYM: [],
        TAHCYM: [],
        SAHCYM: [],
        FOSCYM: [],
        FOMCYM: [],
        CCVCYM: [],
        TEUCYM: [],
        PCAPCYM: [],
        EDSRCYM: [],
        EDCGASCYM: [],
        INTCYM: [],
        CCVGEN: [],
        CCVNOC: [],
        CCVVHC: [],
        COFGEN: [],
        COFNOC: [],
        COFVHC: [],
        FOMGEN: [],
        FOMNOC: [],
        FOMVHC: [],
        FOSGEN: [],
        FOSNOC: [],
        FOSVHC: [],
        GEN: [],
        INTGEN: [],
        INTNOC: [],
        INTVHC: [],
        NOC: [],
        PCAPGEN: [],
        PCAPNOC: [],
        PCAPVHC: [],
        PMHGEN: [],
        PMHNOC: [],
        PMHVHC: [],
        SAHGEN: [],
        SAHNOC: [],
        SAHVHC: [],
        TEUGEN: [],
        TEUNOC: [],
        TEUVHC: [],
        VHC: [],
      },
      day,
      firstLoad
    )
    .then(
      ({
        PCB,
        CYM,
        PMHPCB,
        TAHPCB,
        SAHPCB,
        FOSPCB,
        FOMPCB,
        CCVPCB,
        TEUPCB,
        PCAPPCB,
        EDSRPCB,
        EDCGASPCB,
        INTPCB,
        PMHCYM,
        TAHCYM,
        SAHCYM,
        FOSCYM,
        FOMCYM,
        CCVCYM,
        TEUCYM,
        PCAPCYM,
        EDSRCYM,
        EDCGASCYM,
        INTCYM,
        CCVGEN,
        CCVNOC,
        CCVVHC,
        COFGEN,
        COFNOC,
        COFVHC,
        FOMGEN,
        FOMNOC,
        FOMVHC,
        FOSGEN,
        FOSNOC,
        FOSVHC,
        GEN,
        INTGEN,
        INTNOC,
        INTVHC,
        NOC,
        PCAPGEN,
        PCAPNOC,
        PCAPVHC,
        PMHGEN,
        PMHNOC,
        PMHVHC,
        SAHGEN,
        SAHNOC,
        SAHVHC,
        TEUGEN,
        TEUNOC,
        TEUVHC,
        VHC,
      }) => {
        if (PCB?.length > 0 && firstLoad) {
          if (PCB[0]?.x) {
            maxDate = moment(PCB[0]?.x)
              .tz('Europe/Madrid')
              .format('DD / MM / YYYY');
            $('#datepicker').val(maxDate);
            $('#datepicker').datepicker('option', 'maxDate', maxDate);
            templatepvpc();
          }
        }
        showSpinner(false);
        dataPVPC.values = { PCB, CYM, GEN, NOC, VHC };
        dataPCB.values = {
          PMHPCB,
          TAHPCB,
          SAHPCB,
          FOSPCB,
          FOMPCB,
          CCVPCB,
          TEUPCB,
          PCAPPCB,
          EDSRPCB,
          EDCGASPCB,
          INTPCB,
        };
        if (
          (dataPCB.values != null &&
            dataPCB.values.TAHPCB != null &&
            dataPCB.values.TAHPCB[0].y != null &&
            dataPCB.values.TAHPCB[0].y == '--' &&
            dataPCB.values.TAHPCB[1].y == '--') ||
          dataPCB.values.TAHPCB == null
        ) {
          dataPCB.values = {
            PMHPCB,
            SAHPCB,
            FOSPCB,
            FOMPCB,
            CCVPCB,
            TEUPCB,
            PCAPPCB,
            EDSRPCB,
            EDCGASPCB,
            INTPCB,
          };
          mercadosTAHPCB = false;
        }
        dataCYM.values = {
          PMHCYM,
          TAHCYM,
          SAHCYM,
          FOSCYM,
          FOMCYM,
          CCVCYM,
          TEUCYM,
          PCAPCYM,
          EDSRCYM,
          EDCGASCYM,
          INTCYM,
        };
        if (
          (dataCYM.values != null &&
            dataCYM.values.TAHCYM != null &&
            dataCYM.values.TAHCYM[0].y != null &&
            dataCYM.values.TAHCYM[0].y == '--' &&
            dataCYM.values.TAHCYM[1].y == '--') ||
          dataCYM.values.TAHCYM == null
        ) {
          dataCYM.values = {
            PMHCYM,
            SAHCYM,
            FOSCYM,
            FOMCYM,
            CCVCYM,
            TEUCYM,
            PCAPCYM,
            EDSRCYM,
            EDCGASCYM,
            INTCYM,
          };
          mercadosTAHCYM = false;
        }
        dataGEN.values = {
          PMHGEN,
          SAHGEN,
          FOSGEN,
          FOMGEN,
          CCVGEN,
          TEUGEN,
          PCAPGEN,
          COFGEN,
          INTGEN,
        };
        dataNOC.values = {
          PMHNOC,
          SAHNOC,
          FOSNOC,
          FOMNOC,
          CCVNOC,
          TEUNOC,
          PCAPNOC,
          COFNOC,
          INTNOC,
        };
        dataVHC.values = {
          PMHVHC,
          SAHVHC,
          FOSVHC,
          FOMVHC,
          CCVVHC,
          TEUVHC,
          PCAPVHC,
          COFVHC,
          INTVHC,
        };
        dataGridGraphs();
        let unitVal = document.getElementById('selectYear').getAttribute('val');
        if (unitVal == 1 || unitVal == null) {
          adaptToUnit('kWh');
        }
        update();
        document
          .querySelector('.esios-pvpc__data-pvpc')
          .classList.remove('hidden');
      }
    )
    .catch((error) => {
      dataGridGraphs('error');
      remove();
      showSpinner(false);
      showNoData(true);
    });
};

const renderCharts = () => {
  document
    .querySelector('.toolbar-analysis')
    .setAttribute(
      'href',
      `/${i18n.t('routes.lng')}/${i18n.t(
        'routes.analysis'
      )}/1001?vis=1&start_date=${moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      )
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY')}T00%3A00&end_date=${moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      )
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY')}T23%3A55&compare_start_date=${moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      )
        .tz('Europe/Madrid')
        .subtract('1', 'day')
        .format(
          'DD-MM-YYYY'
        )}T00%3A00&groupby=hour&compare_indicators=1013,1014,1015`
    );

  let { chartPVPC, parsedData, options1 } = renderPVPC(dataPVPC.values);
  let { chartPCB, parsedDataPCB, options2 } = renderPCB(dataPCB.values);
  let { chartCYM, parsedDataCYM, options3 } = renderCYM(dataCYM.values);
  let { chartGEN, parsedDataGEN, options4 } = renderGEN(dataGEN.values);
  let { chartNOC, parsedDataNOC, options5 } = renderNOC(dataNOC.values);
  let { chartVHC, parsedDataVHC, options6 } = renderVHC(dataVHC.values);

  syncTrailsOfCharts([
    chartPVPC,
    chartPCB,
    chartCYM,
    chartGEN,
    chartNOC,
    chartVHC,
  ]);
  if (chartNOC) {
    renderBottomBar([
      { id: '#pvpcPCBCYM', data: parsedData, options: options1 },
      { id: '#pvpcGEN', data: [parsedData[0]], options: options2 },
      { id: '#pvpcNOC', data: [parsedData[1]], options: options3 },
      { id: '#pvpcVHC', data: [parsedData[2]], options: options4 },
    ]);
  } else {
    renderBottomBar([
      { id: '#pvpcPCBCYM', data: parsedData, options: options1 },
      { id: '#pvpcPCB', data: [parsedData[0]], options: options5 },
      { id: '#pvpcCYM', data: [parsedData[1]], options: options6 },
    ]);
  }
};

const renderBottomBar = (data) => {
  data.forEach((el) => {
    _renderColorIndicator(el.data, el.id, el.options);
  });
};
const dataGridGraphs = (info) => {
  document.querySelector('.esios-pvpc__grid').style.display =
    info === 'error' ? 'none' : 'grid';
};

const showNoData = (show) => {
  let type = show ? 'block' : 'none';
  let data = show ? 'none' : 'block';
  let action = show ? 'add' : 'remove';
  document.querySelectorAll('.esios-pvpc__no-data').forEach((container) => {
    container.style.display = type;
  });
  document.querySelectorAll('.esios-pvpc__data').forEach((container) => {
    container.style.display = data;
  });
  show &&
    document.querySelector('.esios-pvpc__data-pvpc').classList.add('hidden');

  document
    .getElementById('pvpcPCBCYM')
    .classList[action]('esios-pvpc__no-data-graphic');
  document
    .querySelector('#pvpcCYM .esios-pvpc__chart')
    .classList[action]('esios-pvpc__no-data-graphic');
  document
    .querySelector('#pvpcPCB .esios-pvpc__chart')
    .classList[action]('esios-pvpc__no-data-graphic');
};

const showSpinner = (show) => {
  let type = show ? 'flex' : 'none';
  document.querySelectorAll('.spinner-container').forEach((spinner) => {
    spinner.style.display = type;
  });
};

const update = () => {
  remove();
  renderCharts();
  window.addEventListener('resize', () => {
    remove();
    renderCharts();
  });
};

const remove = () => {
  if (!!document.querySelector('#pvpcPCBCYM svg')) {
    document.querySelector('#pvpcPCBCYM svg')?.remove();
    document.querySelector('#pvpcPCB svg')?.remove();
    document.querySelector('#pvpcCYM svg')?.remove();
    document.querySelector('#pvpcGEN svg')?.remove();
    document.querySelector('#pvpcNOC svg')?.remove();
    document.querySelector('#pvpcVHC svg')?.remove();
  }
};

const generateSelect = () => {
  const options = [
    { value: 'kWh', isSelected: true },
    { value: 'MWh', isSelected: false },
  ];
  $('#selectUnit').selectmenu({
    appendTo: '#selectYear',
  });

  options.forEach(({ value, isSelected }, i) => {
    $('#selectUnit').append(
      $('<option>', {
        value: i + 1,
        text: value,
      })
    );
  });

  $('#selectYear').on('selectmenuchange', function (event, ui) {
    if (dataPVPC.unit !== ui.item.label) {
      adaptToUnit(ui.item.label);
    }
  });
};

const parseToKWH = (values) =>
  values.map(({ x, y, h }) => ({ x, y: y / 1000, h }));
const parseToMWH = (values) =>
  values.map(({ x, y, h }) => ({ x, y: y * 1000, h }));

const changeLegend = (unit) => {
  legend[unit].forEach(
    (value, index) =>
      (document.getElementById('legendColor' + (index + 1)).textContent = value)
  );
};

const innerToHTML = (ids, data) => {
  ids.forEach((id, index) => {
    let num = data[index].y;
    if (num == 0) {
      document.querySelector(id)
        ? (document.querySelector(id).innerHTML = '-')
        : '';
      return '';
    }
    num =
      dataPVPC.unit === 'kWh'
        ? Utils.toNumber(num, 5, 'comma')
        : Utils.toNumber(num, 2, 'comma');
    document.querySelector(id)
      ? (document.querySelector(id).innerHTML = num)
      : '';
  });
};

const adaptToUnit = (unit = 'MWh') => {
  changeLegend(unit);
  let units = document.querySelectorAll('.esios-pvpc__unit');
  units.forEach((span) => (span.innerHTML = '€/' + unit));
  dataPVPC.unit = unit;
  if (unit == 'kWh') {
    Object.entries(dataPVPC.values).map(
      ([key, values]) => (dataPVPC.values[key] = [...parseToKWH(values)])
    );
    Object.entries(dataPCB.values).map(
      ([key, values]) => (dataPCB.values[key] = [...parseToKWH(values)])
    );
    Object.entries(dataCYM.values).map(
      ([key, values]) => (dataCYM.values[key] = [...parseToKWH(values)])
    );
    Object.entries(dataGEN.values).map(
      ([key, values]) => (dataGEN.values[key] = [...parseToKWH(values)])
    );
    Object.entries(dataNOC.values).map(
      ([key, values]) => (dataNOC.values[key] = [...parseToKWH(values)])
    );
    Object.entries(dataVHC.values).map(
      ([key, values]) => (dataVHC.values[key] = [...parseToKWH(values)])
    );
    update();
  } else {
    Object.entries(dataPVPC.values).map(
      ([key, values]) => (dataPVPC.values[key] = [...parseToMWH(values)])
    );
    Object.entries(dataPCB.values).map(
      ([key, values]) => (dataPCB.values[key] = [...parseToMWH(values)])
    );
    Object.entries(dataCYM.values).map(
      ([key, values]) => (dataCYM.values[key] = [...parseToMWH(values)])
    );
    Object.entries(dataGEN.values).map(
      ([key, values]) => (dataGEN.values[key] = [...parseToMWH(values)])
    );
    Object.entries(dataNOC.values).map(
      ([key, values]) => (dataNOC.values[key] = [...parseToMWH(values)])
    );
    Object.entries(dataVHC.values).map(
      ([key, values]) => (dataVHC.values[key] = [...parseToMWH(values)])
    );
    update();
  }
};

const tah = () => {
  let Datepicker = $('#datepicker').val();
  let DateTAH = '01-01-2024';
  let respuesta = false;
  if (Datepicker != null) {
    let dateParts = Datepicker.split('/').map((part) => part.trim());
    // Create a Date object with the correct format (year, month, day)
    let DateSEL = dateParts[0]
      .concat('-', dateParts[1])
      .concat('-', dateParts[2]);
    // Divide las cadenas en partes: día, mes y año
    const [dayTAH, monthTAH, yearTAH] = DateTAH.split('-');
    const [dayFIN, monthFIN, yearFIN] = DateSEL.split('-');

    // Crea objetos Date
    const dateTAH = new Date(yearTAH, monthTAH - 1, dayTAH);
    const dateSEL = new Date(yearFIN, monthFIN - 1, dayFIN);

    // Compara las fechas
    if (dateTAH > dateSEL) {
      console.log('DateTAH es mayor que date seleccionada');
    } else if (dateTAH < dateSEL) {
      respuesta = true;
    } else {
      respuesta = true;
    }
  } else {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams != null && urlParams.get('date') != null) {
      let date = urlParams.get('date');
      let DateSEL = date;
      // Divide las cadenas en partes: día, mes y año
      const [dayTAH, monthTAH, yearTAH] = DateTAH.split('-');
      const [dayFIN, monthFIN, yearFIN] = DateSEL.split('-');
      // Crea objetos Date
      const dateTAH = new Date(yearTAH, monthTAH - 1, dayTAH);
      const dateSEL = new Date(yearFIN, monthFIN - 1, dayFIN);

      // Compara las fechas
      if (dateTAH > dateSEL) {
        console.log('DateTAH es mayor que date seleccionada');
      } else if (dateTAH < dateSEL) {
        respuesta = true;
      } else {
        respuesta = true;
      }
    } else {
      const fechaactual = new Date();
      const fechaTAH = new Date('01-01-2024');
      if (fechaactual > fechaTAH) {
        respuesta = true;
      }
      if (fechaactual == fechaTAH) {
        respuesta = true;
      }
    }
  }
  return respuesta;
};

const renderPVPC = (data) => {
  let twoGraphs = data.CYM[0].y;
  const graphColors = ['#DF4A32', '#00A1DA', '#74BA05'];
  let parsedData = Object.entries(data)
    .map(([key, values], index) => {
      if (!isNaN(values[0].y)) {
        return values;
      }
    })
    .filter((el) => el !== undefined)
    .map((values, index) => {
      return {
        type: 'line',
        values: values.map((el, index) => {
          return el;
        }),
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });

  let options = {
    target: d3.select('#pvpcPCBCYM').node(),
    margin: '10 8 25 45',
    noFakeValue: false,
    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      ticks: [d3.time.hours, 1],
      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();

          let finalHour = hour < 10 ? '0' + hour : hour;

          if (Utils.isMobileDevice()) {
            if (i % 2) {
              return '';
            } else {
              return finalHour;
            }
          }
          return finalHour;
        },
      },
    },
    yaxis: {
      fit: false,
      fullGrid: true,
      textMarginTop: -6,
      ticks: [4],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }
          return Utils.toNumber(
            y,
            dataPVPC.unit === 'kWh' ? 2 : 0,
            'comma',
            'zero'
          );
        },
      },
    },
  };

  let chartPVPC = new Charicharts.Chart(options, parsedData);
  chartPVPC.on('Trail/moved', function (data, d) {
    let idsArr,
      firstArr = ['#pricePCB', '#priceCYM'],
      secondArr = ['#priceGEN', '#priceNOC', '#priceVHC'];
    if (!isNaN(twoGraphs)) {
      idsArr = firstArr;
      document.querySelectorAll('.pvpc__main-Legend').forEach((el) => {
        el.style.display = 'block';
      });
      document.querySelectorAll('.pvpc__second-Legend').forEach((el) => {
        el.style.display = 'none';
      });
    } else {
      idsArr = secondArr;
      document.querySelectorAll('.pvpc__main-Legend').forEach((el) => {
        el.style.display = 'none';
      });
      document.querySelectorAll('.pvpc__second-Legend').forEach((el) => {
        el.style.display = 'block';
      });
    }
    let hour = false;
    hour = moment(d).format('HH:mm');
    document.querySelector(
      '.esios-toolbar.esios-toolbar--cym .mod-date'
    ).innerHTML = hour;
    document.getElementById('main-timer').innerHTML = hour;
    innerToHTML(idsArr, data);
  });

  var svg = d3.select('#pvpcPCBCYM');
  var container = svg.select('.g-main').append('g').attr('class', 'triangles');

  var x = chartPVPC.scale.x;
  var y = chartPVPC.scale.y;
  var points = [];

  _.each(
    _.where(chartPVPC.series.list, { type: 'line' }),
    function (serie) {
      var minPoint = _.min(serie.values, function (value) {
        return value.y;
      });
      var maxPoint = _.max(serie.values, function (value) {
        return value.y;
      });
      points.push(
        _.extend(
          {
            direction: 'down',
            color: serie.color,
          },
          minPoint
        )
      );
      points.push(
        _.extend(
          {
            direction: 'up',
            color: serie.color,
          },
          maxPoint
        )
      );
    },
    this
  );

  container
    .selectAll('path')
    .data(points)
    .enter()
    .append('path')
    .attr('class', function (d, i) {
      return 'triangle-' + d.direction;
    })
    .attr('d', function (d, i) {
      return d3.svg
        .symbol()
        .type('triangle-' + d.direction)
        .size(60)(d, i);
    })
    .attr('transform', function (d) {
      return 'translate(' + x(d.x) + ',' + y(d.y) + ')';
    })
    .attr('style', function (d) {
      return 'fill:' + d.color + '; stroke: white; stroke-width: 1;';
    });

  return {
    chartPVPC,
    parsedData,
    options1: options,
  };
};

const renderPCB = (data) => {
  if (isNaN(data.CCVPCB[0].y)) {
    return (document.getElementById('pvpcPCB').style.display = 'none');
  } else {
    document.getElementById('pvpcPCB').style.display = 'block';
  }
  const graphColors = [
    'rgba(223, 74, 50, 1)',
    'rgba(223, 74, 50, 0.8888888888888888)',
    'rgba(223, 74, 50, 0.7777777777777778)',
    'rgba(223, 74, 50, 0.6666666666666667)',
    'rgba(223, 74, 50, 0.5555555555555556)',
    'rgba(223, 74, 50, 0.4444444444444444)',
    'rgba(223, 74, 50, 0.33333333333333337)',
    'rgba(223, 74, 50, 0.22222222222222232)',
    'rgba(223, 74, 50, 0.11111111111111116)',
    'rgba(223, 74, 50, 0.00000000000000001)',
  ];

  let parsedData = Object.entries(data).map(([key, values], index) => {
    return {
      type: 'line',
      values: values.map((el, index) => {
        return el;
      }),
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });

  const newDataParsed = [
    {
      type: 'area',
      stacking: true,
      data: parsedData,
    },
  ];

  let options = {
    target: d3.select('#pvpcPCB > .chari-chart').node(),
    margin: '10 10 25 45',
    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      fit: true,
      ticks: [d3.time.hours, 1],

      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();
          if (parsedData[0].values.length === 25) {
            if (i >= 3) {
              hour = i - 1;
            }
          }

          if (i % 2 === 0) {
            return hour < 10 ? '0' + hour : hour;
          }
        },
      },
    },
    yaxis: {
      fit: false,
      fullGrid: true,
      textMarginTop: -6,
      ticks: [3],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }

          return Utils.toNumber(
            y,
            dataPVPC.unit === 'kWh' ? 2 : 0,
            'comma',
            'zero'
          );
        },
      },
    },
  };

  let chartPCB = new Charicharts.Chart(options, newDataParsed);
  chartPCB.on('Trail/moved', function (parsedData) {
    const ids = [
      '#pricePMHPCB',
      '#priceTAHPCB',
      '#priceSAHPCB',
      '#priceFOSPCB',
      '#priceFOMPCB',
      '#priceCCVPCB',
      '#priceTEUPCB',
      '#pricePCAPPCB',
      '#priceEDSRPCB',
      '#priceEDMACPPCB',
      '#priceINTPCB',
    ];
    if (!mercadosTAHPCB && !tah()) {
      const antiguosidsTAHPCB = [
        '#pricePMHPCB',
        '#priceSAHPCB',
        '#priceFOSPCB',
        '#priceFOMPCB',
        '#priceCCVPCB',
        '#priceTEUPCB',
        '#pricePCAPPCB',
        '#priceEDSRPCB',
        '#priceEDMACPPCB',
        '#priceINTPCB',
      ];
      innerToHTML(antiguosidsTAHPCB, parsedData[0]);
    } else {
      if (ids.length == parsedData[0].length) {
        innerToHTML(ids, parsedData[0]);
      } else {
        const antiguosidsTAHPCB = [
          '#pricePMHPCB',
          '#priceSAHPCB',
          '#priceFOSPCB',
          '#priceFOMPCB',
          '#priceCCVPCB',
          '#priceTEUPCB',
          '#pricePCAPPCB',
          '#priceEDSRPCB',
          '#priceEDMACPPCB',
          '#priceINTPCB',
        ];
        if (document.querySelector('#priceTAHPCB') != null) {
          document.querySelector('#priceTAHPCB').innerHTML = document
            .querySelector('#priceTAHPCB')
            .innerHTML.replace(
              document.querySelector('#priceTAHPCB').innerHTML,
              '-'
            );
        }
        innerToHTML(antiguosidsTAHPCB, parsedData[0]);
      }
    }
  });

  let seri = document.querySelectorAll('#pvpcPCB .series path');
  setStrokeColor(seri, graphColors);

  return {
    chartPCB,
    parsedDataPCB: parsedData,
    options2: options,
  };
};

const renderCYM = (data) => {
  if (isNaN(data.CCVCYM[0].y)) {
    return (document.getElementById('pvpcCYM').style.display = 'none');
  } else {
    document.getElementById('pvpcCYM').style.display = 'block';
  }
  const graphColors = [
    'rgba(0, 161, 218, 1)',
    'rgba(0, 161, 218, 0.8888888888888888)',
    'rgba(0, 161, 218, 0.7777777777777778)',
    'rgba(0, 161, 218, 0.6666666666666667)',
    'rgba(0, 161, 218, 0.5555555555555556)',
    'rgba(0, 161, 218, 0.4444444444444444)',
    'rgba(0, 161, 218, 0.33333333333333337)',
    'rgba(0, 161, 218, 0.22222222222222232)',
    'rgba(0, 161, 218, 0.11111111111111116)',
    'rgba(0, 161, 218, 0.00000000000000001)',
  ];
  let parsedData = Object.entries(data).map(([key, values], index) => {
    return {
      type: 'line',
      values: values.map((el, index) => {
        return el;
      }),
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });

  const newDataParsed = [
    {
      type: 'area',
      stacking: true,
      data: parsedData,
    },
  ];

  let options = {
    target: d3.select('#pvpcCYM > .chari-chart').node(),
    margin: '10 10 25 45',

    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      fit: true,
      ticks: [d3.time.hours, 1],

      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();
          if (parsedData[0].values.length === 25) {
            if (i === 24) {
              hour = i - 1;
            }
            if (i >= 3 && i !== 24) {
              hour = i - 1;
            }
          }
          if (i % 2 === 0) {
            return hour < 10 ? '0' + hour : hour;
          }
        },
      },
    },
    yaxis: {
      fit: false,
      fullGrid: true,
      textMarginTop: -6,
      ticks: [3],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }
          return Utils.toNumber(
            y,
            dataPVPC.unit === 'kWh' ? 2 : 0,
            'comma',
            'zero'
          );
        },
      },
    },
  };

  let chartCYM = new Charicharts.Chart(options, newDataParsed);
  chartCYM.on('Trail/moved', function (parsedData) {
    const ids = [
      '#pricePMHCYM',
      '#priceTAHCYM',
      '#priceSAHCYM',
      '#priceFOSCYM',
      '#priceFOMCYM',
      '#priceCCVCYM',
      '#priceTEUCYM',
      '#pricePCAPCYM',
      '#priceEDSRCYM',
      '#priceEDMACPCYM',
      '#priceINTCYM',
    ];
    if (!mercadosTAHCYM && !tah()) {
      const antiguosidsTAHCYM = [
        '#pricePMHCYM',
        '#priceSAHCYM',
        '#priceFOSCYM',
        '#priceFOMCYM',
        '#priceCCVCYM',
        '#priceTEUCYM',
        '#pricePCAPCYM',
        '#priceEDSRCYM',
        '#priceEDMACPCYM',
        '#priceINTCYM',
      ];
      innerToHTML(antiguosidsTAHCYM, parsedData[0]);
    } else {
      if (ids.length == parsedData[0].length) {
        innerToHTML(ids, parsedData[0]);
      } else {
        const antiguosidsTAHCYM = [
          '#pricePMHCYM',
          '#priceSAHCYM',
          '#priceFOSCYM',
          '#priceFOMCYM',
          '#priceCCVCYM',
          '#priceTEUCYM',
          '#pricePCAPCYM',
          '#priceEDSRCYM',
          '#priceEDMACPCYM',
          '#priceINTCYM',
        ];
        if (document.querySelector('#priceTAHCYM') != null) {
          document.querySelector('#priceTAHCYM').innerHTML = document
            .querySelector('#priceTAHCYM')
            .innerHTML.replace(
              document.querySelector('#priceTAHCYM').innerHTML,
              '-'
            );
        }
        innerToHTML(antiguosidsTAHCYM, parsedData[0]);
      }
    }
  });

  let seri = document.querySelectorAll('#pvpcCYM .series path');
  setStrokeColor(seri, graphColors);
  return {
    chartCYM,
    parsedDataCYM: parsedData,
    options3: options,
  };
};

const renderGEN = (data) => {
  if (isNaN(data.CCVGEN[0].y)) {
    return (document.getElementById('pvpcGEN').style.display = 'none');
  } else {
    document.getElementById('pvpcGEN').style.display = 'block';
  }
  const graphColors = [
    'rgba(223, 74, 50, 1)',
    'rgba(223, 74, 50, 0.8888888888888888)',
    'rgba(223, 74, 50, 0.7777777777777778)',
    'rgba(223, 74, 50, 0.6666666666666667)',
    'rgba(223, 74, 50, 0.5555555555555556)',
    'rgba(223, 74, 50, 0.4444444444444444)',
    'rgba(223, 74, 50, 0.33333333333333337)',
    'rgba(223, 74, 50, 0.22222222222222232)',
    'rgba(223, 74, 50, 0.11111111111111116)',
  ];
  let parsedData = Object.entries(data).map(([key, values], index) => {
    return {
      type: 'line',
      values: values.map((el, index) => {
        return el;
      }),
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });
  const newDataParsed = [
    {
      type: 'area',
      stacking: true,
      data: parsedData,
    },
  ];

  let options = {
    target: d3.select('#pvpcGEN > .chari-chart').node(),
    margin: '10 10 25 45',

    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      fit: true,
      ticks: [d3.time.hours, 2],

      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();
          if (parsedData[0].values.length === 25) {
            if (i === 24) {
              hour = i - 1;
            }
            if (i >= 3 && i !== 24) {
              hour = i - 1;
            }
          }

          if (i % 2 === 0 || hour % 2 === 0) {
            return hour < 10 ? '0' + hour : hour;
          }
        },
      },
    },
    yaxis: {
      // fit: true,
      fullGrid: true,
      textMarginTop: -6,
      ticks: [6],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }
          let parseNum = dataPVPC.unit === 'kWh' ? y * 100 : y;
          if (parseNum % 2 === 0) {
            return Utils.toNumber(
              y,
              dataPVPC.unit === 'kWh' ? 2 : 0,
              'comma',
              'zero'
            );
          }
        },
      },
    },
  };
  let chartGEN = new Charicharts.Chart(options, newDataParsed);

  chartGEN.on('Trail/moved', function (parsedData) {
    const ids = [
      '#pricePMHGEN',
      '#priceSAHGEN',
      '#priceFOSGEN',
      '#priceFOMGEN',
      '#priceCCVGEN',
      '#priceTEUGEN',
      '#pricePCAPGEN',
      '#priceINTGEN',
    ];
    innerToHTML(ids, parsedData[0]);
  });
  let seri = document.querySelectorAll('#pvpcGEN .series path');
  setStrokeColor(seri, graphColors);

  return {
    chartGEN,
    parsedDataGEN: parsedData,
    options4: options,
  };
};
const renderNOC = (data) => {
  if (isNaN(data.CCVNOC[0].y)) {
    return (document.getElementById('pvpcNOC').style.display = 'none');
  } else {
    document.getElementById('pvpcNOC').style.display = 'block';
  }

  const graphColors = [
    'rgba(0, 161, 218, 1)',
    'rgba(0, 161, 218, 0.8888888888888888)',
    'rgba(0, 161, 218, 0.7777777777777778)',
    'rgba(0, 161, 218, 0.6666666666666667)',
    'rgba(0, 161, 218, 0.5555555555555556)',
    'rgba(0, 161, 218, 0.4444444444444444)',
    'rgba(0, 161, 218, 0.33333333333333337)',
    'rgba(0, 161, 218, 0.22222222222222232)',
    'rgba(0, 161, 218, 0.11111111111111116)',
  ];
  let parsedData = Object.entries(data).map(([key, values], index) => {
    return {
      type: 'line',
      values: values.map((el, index) => {
        return el;
      }),
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });

  const newDataParsed = [
    {
      type: 'area',
      stacking: true,
      data: parsedData,
    },
  ];

  let options = {
    target: d3.select('#pvpcNOC > .chari-chart').node(),
    margin: '10 10 25 45',

    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      fit: true,
      ticks: [d3.time.hours, 2],

      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();
          if (parsedData[0].values.length === 25) {
            if (i === 24) {
              hour = i - 1;
            }
            if (i >= 3 && i !== 24) {
              hour = i - 1;
            }
          }

          if (i % 2 === 0 || hour % 2 === 0) {
            return hour < 10 ? '0' + hour : hour;
          }
        },
      },
    },
    yaxis: {
      textMarginTop: -6,
      ticks: [3],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }
          return Utils.toNumber(
            y,
            dataPVPC.unit === 'kWh' ? 2 : 0,
            'comma',
            'zero'
          );
        },
      },
    },
  };

  let chartNOC = new Charicharts.Chart(options, newDataParsed);

  chartNOC.on('Trail/moved', function (parsedData) {
    const ids = [
      '#pricePMHNOC',
      '#priceSAHNOC',
      '#priceFOSNOC',
      '#priceFOMNOC',
      '#priceCCVNOC',
      '#priceTEUNOC',
      '#pricePCAPNOC',
      '#priceINTNOC',
    ];

    innerToHTML(ids, parsedData[0]);
  });
  let seri = document.querySelectorAll('#pvpcNOC .series path');
  setStrokeColor(seri, graphColors);

  return {
    chartNOC,
    parsedDataNOC: parsedData,
    options5: options,
  };
};
const renderVHC = (data) => {
  if (isNaN(data.CCVVHC[0].y)) {
    return (document.getElementById('pvpcVHC').style.display = 'none');
  } else {
    document.getElementById('pvpcVHC').style.display = 'block';
  }

  const graphColors = [
    'rgba(116, 186, 5, 1)',
    'rgba(116, 186, 5, 0.8888888888888888)',
    'rgba(116, 186, 5, 0.7777777777777778)',
    'rgba(116, 186, 5, 0.6666666666666667)',
    'rgba(116, 186, 5, 0.5555555555555556)',
    'rgba(116, 186, 5, 0.4444444444444444)',
    'rgba(116, 186, 5, 0.33333333333333337)',
    'rgba(116, 186, 5, 0.22222222222222232)',
    'rgba(116, 186, 5, 0.11111111111111116)',
  ];
  let parsedData = Object.entries(data).map(([key, values], index) => {
    return {
      type: 'line',
      values: values.map((el, index) => {
        return el;
      }),
      dots: false,
      interpolation: 'linear',
      color: graphColors[index],
    };
  });

  const newDataParsed = [
    {
      type: 'area',
      stacking: true,
      data: parsedData,
    },
  ];

  let options = {
    target: d3.select('#pvpcVHC > .chari-chart').node(),
    margin: '10 10 25 45',

    trail: {
      enabled: true,
      parseStep: function (date) {
        return moment(date).startOf('hour').toDate();
      },
      initXValue: function () {
        return 0;
      },
    },
    xaxis: {
      scale: 'time',
      fit: true,
      ticks: [d3.time.hours, 2],

      bottom: {
        tickFormat: function (d, i) {
          let date = moment(d);
          let hour = date.hours();
          if (parsedData[0].values.length === 25) {
            if (i === 24) {
              hour = i - 1;
            }
            if (i >= 3 && i !== 24) {
              hour = i - 1;
            }
          }

          if (i % 2 === 0 || hour % 2 === 0) {
            return hour < 10 ? '0' + hour : hour;
          }
        },
      },
    },
    yaxis: {
      fit: false,
      fullGrid: true,
      textMarginTop: -6,
      ticks: [3],
      left: {
        label: '€/' + dataPVPC.unit,
        tickFormat: function (y) {
          if (y === 0) {
            return dataPVPC.unit === 'kWh'
              ? i18n.t('routes.lng') === 'en'
                ? '0.00'
                : '0,00'
              : '0';
          }
          return Utils.toNumber(
            y,
            dataPVPC.unit === 'kWh' ? 2 : 0,
            'comma',
            'zero'
          );
        },
      },
    },
  };

  let chartVHC = new Charicharts.Chart(options, newDataParsed);

  chartVHC.on('Trail/moved', function (parsedData) {
    const ids = [
      '#pricePMHVHC',
      '#priceSAHVHC',
      '#priceFOSVHC',
      '#priceFOMVHC',
      '#priceCCVVHC',
      '#priceTEUVHC',
      '#pricePCAPVHC',
      '#priceINTVHC',
    ];

    innerToHTML(ids, parsedData[0]);
  });

  let seri = document.querySelectorAll('#pvpcVHC .series path');
  setStrokeColor(seri, graphColors);

  return {
    chartVHC,
    parsedDataVHC: parsedData,
    options6: options,
  };
};

const modalDownload = [
  { id: '1', download: { file: '71', format: 'xls' }, active: true },
  { id: '2', download: { file: '80', format: 'xml' } },
  { id: '3', download: { file: '79', format: 'xml' } },
  { id: '4', download: { file: '78', format: 'xml' } },
];

let renderModalDownload = (currentDatetime) => {
  $('#downloadCalendar').datepicker({
    firstDay: 1,
    dateFormat: 'dd/mm/yy',
    altFormat: 'dd / mm / yy',
    maxDate: new Date(),
    minDate: new Date(2014, 1, 1),
    monthNames: [
      i18n.t('january'),
      i18n.t('february'),
      i18n.t('march'),
      i18n.t('april'),
      i18n.t('ma'),
      i18n.t('june'),
      i18n.t('july'),
      i18n.t('august'),
      i18n.t('september'),
      i18n.t('october'),
      i18n.t('november'),
      i18n.t('december'),
    ],
    monthNamesShort: [
      i18n.t('jan'),
      i18n.t('feb'),
      i18n.t('mar'),
      i18n.t('apr'),
      i18n.t('may'),
      i18n.t('june'),
      i18n.t('july'),
      i18n.t('aug'),
      i18n.t('sep'),
      i18n.t('oct'),
      i18n.t('nov'),
      i18n.t('dec'),
    ],
    dayNames: [
      i18n.t('sunday'),
      i18n.t('monday'),
      i18n.t('tuesday'),
      i18n.t('wednesday'),
      i18n.t('thursday'),
      i18n.t('friday'),
      i18n.t('saturday'),
    ],
    dayNamesShort: [
      i18n.t('sun'),
      i18n.t('mon'),
      i18n.t('tue'),
      i18n.t('wed'),
      i18n.t('thu'),
      i18n.t('fri'),
      i18n.t('sat'),
    ],
    dayNamesMin: [
      i18n.t('Su'),
      i18n.t('M'),
      i18n.t('T'),
      i18n.t('W'),
      i18n.t('Th'),
      i18n.t('F'),
      i18n.t('S'),
    ],
    gotoCurrent: true,
    weekHeader: i18n.t('week'),
    changeMonth: true,
    changeYear: true,
    showButtonPanel: true,
    todayBtn: 'linked',
    currentText: i18n.t('today'),
  });
  $('#downloadCalendar').datepicker(
    'setDate',
    moment(currentDatetime, 'DD / MM / YYYY').format('DD/MM/YYYY')
  );

  let classActive = 'esios-pvpc__tab--active';
  let textoDefault = i18n.t(
    'pvpc_publication_' + modalDownload[0].id + '_content'
  );
  document.getElementById('textModalDownload').innerText = textoDefault;

  modalDownload.map(({ id, download: { file, format } }) => {
    let tab = document.getElementById('tab' + id);

    if (
      document.getElementById('tab1').getAttribute('class').includes('active')
    ) {
      // get date
      let btn = document.getElementById('btnDownload');
      btn.replaceWith(btn.cloneNode(true));
      document.getElementById('btnDownload').addEventListener('click', () => {
        let date = moment($('#downloadCalendar').datepicker('getDate'));

        date = date.format('YYYY-MM-DD');
        servicespvpc.downloadFile({ file: '71', format: 'xls' }, date);
      });
    }

    tab.addEventListener('click', () => {
      let activo = document.getElementsByClassName(classActive);
      activo[0].classList.remove(classActive);
      tab.className = 'esios-pvpc__tab ' + classActive;

      let texto = i18n.t('pvpc_publication_' + id + '_content');
      document.getElementById('textModalDownload').innerText = texto;

      //para resetear el evento y no se duplique
      let btn = document.getElementById('btnDownload');
      btn.replaceWith(btn.cloneNode(true));

      document.getElementById('btnDownload').addEventListener('click', () => {
        let date = moment($('#downloadCalendar').datepicker('getDate'));

        date = date.format('YYYY-MM-DD');
        servicespvpc.downloadFile({ file, format }, date);
      });
    });
  });
};

const syncTrailsOfCharts = (arrays) => {
  for (let elem of arrays) {
    elem !== undefined
      ? elem.on('Trail/moved', (x, d) => {
          for (let elema of arrays) {
            if (elem !== elema) {
              elema !== undefined ? elema.trigger('Trail/move', [d]) : null;
            }
          }
        })
      : null;
  }
};

export function _renderColorIndicator(series, target, opts) {
  let optsMargin = 10;
  if (opts) {
    optsMargin = parseInt(opts.margin.split(' ')[1]) * 3;
  }
  var zones = _calculateColorZones(series);
  var $svg = $(target).find('svg .xaxis.bottom');
  if (!$svg.length) {
    return;
  }
  var widthRef = $svg[0].getBBox().width - optsMargin - 29;
  var xRef = -1;

  _.each(
    zones,
    function (zone) {
      var element = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      );
      element.setAttribute('x', zone.start * widthRef + xRef);
      element.setAttribute('y', '0');
      element.setAttribute('width', (zone.end - zone.start) * widthRef);
      element.setAttribute('height', '7');
      element.setAttribute(
        'style',
        'fill:' + colorIndicatorHex[zone.color] + ';'
      );
      $svg.append(element);
    },
    this
  );
}

function _calculateColorZones(series) {
  var serie = series.filter((serie) => {
    return serie.type === 'line';
  });
  var values = serie[0].values;
  /* Twice the last value */
  // values = values.slice(0, values.length - 1);
  var xScale = d3.time
    .scale()
    .domain(
      d3.extent(
        _.map(values, function (v) {
          return v.x;
        })
      )
    )
    .range([0, 1]);

  /* Always convert the units to MWh for legibility */
  let units = document.querySelector('.esios-pvpc__unit').innerText;
  values = _.map(
    serie[0].values,
    function (value) {
      return {
        x: xScale(value.x), // in [0, 1] scale
        y: units === '€/kWh' ? value.y * 1000 : value.y, //MWh
      };
    },
    this
  );

  /* Maps each point to a zone: 0: green, 1: yellow, 2: red */
  var pointsZones = _.map(
    values,
    function (v) {
      if (colorIndicator.isGreen(v.y)) {
        return 0;
      } else if (colorIndicator.isYellow(v.y)) {
        return 1;
      }
      return 2;
    },
    this
  );
  const checkPointsZones = [...pointsZones];
  var result = [{ count: 1, index: 0, char: checkPointsZones[0] }];
  checkPointsZones.slice(1).forEach((e, i) => {
    if (e == checkPointsZones[i]) {
      result[result.length - 1].count++;
    } else {
      result.push({ count: 1, index: i, char: e });
    }
  });
  var zones = [];
  var xLimit, previousZoneEnd;
  for (var i = 1, j = pointsZones.length; i < j; i++) {
    /* The first case is when we jumped from a adjacent zone */

    if (
      pointsZones[i] !== pointsZones[i - 1] &&
      Math.abs(pointsZones[i] - pointsZones[i - 1]) === 1
    ) {
      /* limitValue is the y value of the line delimiting the tariff zones */
      var limitValue;
      if (pointsZones[i] === 0 || pointsZones[i - 1] === 0) {
        limitValue = 100;
      } else {
        limitValue = 150;
      }
      xLimit = _calculateXIntersection(values[i - 1], values[i], limitValue);
      previousZoneEnd = zones.length === 0 ? 0 : zones[zones.length - 1].end;
      zones.push({
        start: previousZoneEnd,
        end: values[i].x,
        color: pointsZones[i - 1],
      });
    } else if (
      /* The second case is when we jump two zones at once */
      pointsZones[i] !== pointsZones[i - 1] &&
      Math.abs(pointsZones[i] - pointsZones[i - 1]) === 2
    ) {
      xLimit = _calculateXIntersection(
        values[i - 1],
        values[i],
        pointsZones[i - 1] === 0 ? 100 : 150
      );
      previousZoneEnd = zones.length === 0 ? 0 : zones[zones.length - 1].end;
      zones.push({
        start: previousZoneEnd,
        end: xLimit,
        color: pointsZones[i - 1] === 0 ? 0 : 2,
      });

      previousZoneEnd = xLimit;
      xLimit = _calculateXIntersection(
        values[i - 1],
        values[i],
        pointsZones[i - 1] === 0 ? 150 : 100
      );
      zones.push({
        start: previousZoneEnd,
        end: xLimit,
        color: 1,
      });
    }
  }
  /* Whatever how the curves are, we need or one zone or the final zone */
  previousZoneEnd = zones.length === 0 ? 0 : zones[zones.length - 1].end;
  zones.push({
    start: previousZoneEnd,
    end: 2,
    color: pointsZones[pointsZones.length - 1],
  });

  return zones;
}

function _calculateXIntersection(pointA, pointB, lineSlop) {
  var a = (pointB.y - pointA.y) / (pointB.x - pointA.x);
  var b = pointB.y - a * pointB.x;

  return (lineSlop - b) / a;
}

function setStrokeColor(arr, gc) {
  let newSeri = [];
  arr?.forEach((el) => {
    if (!el.getAttribute('d').includes('NaN')) {
      newSeri.push(el);
    }
  });

  if (newSeri.length > 0) {
    newSeri[0].style.stroke = gc[0];
    newSeri[0].style.strokeWidth = '2px';
  }
}
